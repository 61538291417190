import { GroupBase } from 'react-select'
import AsyncSelect, { AsyncProps as SelectProps } from 'react-select/async'

import { Control, Menu, Option, styles } from './Select'

export type SelectAsyncProps = SelectProps<unknown, false, GroupBase<unknown>>

export default function SelectAsync(props: SelectAsyncProps) {
  return (
    <AsyncSelect
      classNamePrefix="select"
      components={{ Control, Menu, Option }}
      styles={styles}
      {...props}
    />
  )
}

import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Dropdown, Form, InputGroup, ListGroup, Pagination, Row, Spinner, Table } from 'react-bootstrap'
import { ArrowLeft, ArrowRight, Search, Sliders } from 'react-feather'

import api from '../utils/api'

export interface ResourceTableProps {
  endpoint: string;
  
  columns: {
    key: string;
    header?: string;
    headerClass?: string;
    accessor?: string;
    render?: Function;
    rowClass?: string;
    sortable?: boolean;
  }[];

  actions?: {
    key: string;
    render: Function;
  }[];

  searchable?: boolean;

  filters?: {
    key: string;
  }[];

  initialSortBy?: { key: string; dir: 'asc'|'desc' } | undefined;

  externalParamValues?: { [key: string]: string|null };

  updateSearchParams?: ((params: URLSearchParams) => void) | null;
}

export default function ResourceTable({ 
  endpoint, 
  columns, 
  actions = [],
  searchable = false, 
  filters = [],
  initialSortBy,
  externalParamValues,
  updateSearchParams = null,
}: ResourceTableProps) {
  const abortController = useRef<AbortController>(new AbortController())

  const [busy, setBusy] = useState<boolean>(true)
  const [data, setData] = useState<{ [key: string]: any }>([])

  const [sortBy, setSortBy] = useState<string|undefined>(initialSortBy?.key)
  const [sortDir, setSortDir] = useState<'asc'|'desc'>(initialSortBy?.dir ?? 'desc')

  const [search, setSearch] = useState<string>('')

  const [page, setPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number>(1)

  const toggleSort = (key: string) => {
    if (key !== sortBy) {
      setSortBy(key)
      setSortDir('desc')
    } else {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    setBusy(true)

    const params = new URLSearchParams()

    if (search !== '') {
      params.set('search', search)
    }
    
    params.set('page', page.toString())

    if (sortBy !== undefined) {
      params.set('sort_by', sortBy)
      params.set('sort_dir', sortDir)
    }

    if (externalParamValues !== undefined) {
      Object.keys(externalParamValues).forEach((param) => {
        const value = externalParamValues[param]

        if (value !== null) {
          params.set(param, value)
        }
      })
    }

    if (updateSearchParams) {
      updateSearchParams(params)
    }

    abortController.current.abort()
    abortController.current = new AbortController()

    const paramString = params.toString()

    api.get(endpoint + (paramString ? `?${paramString}` : ''), { signal: abortController.current.signal })
      .then(response => {
        if (response !== undefined) {
          setData(response.data.data)
          setLastPage(response.data.meta.last_page)
          setBusy(false)
        }
      })

    return () => abortController.current.abort()
  }, [endpoint, externalParamValues, search, page, sortBy, sortDir])

  const shownPages = Array.from((new Set([
    1, Math.min(2, lastPage),
    // ...
    Math.max(page-1, 1), page, Math.min(page+1, lastPage), 
    // ...
    Math.max(lastPage-1, 1), lastPage
  ])).values())

  return (
    <>
      <Card>
        { (filters.length > 0 || searchable) && (
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                { searchable && (
                  <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setTimeout(() => setSearch(e.target.value ?? ''), 800)}
                    />

                    <InputGroup.Text>
                      <Search size="1em" />
                    </InputGroup.Text>
                  </InputGroup>
                ) }
              </Col>

              {/* <Col xs="auto" className="me-n3">
                <Select options={pagesOptions} layout="flush" size="sm" />
              </Col> */}

              { (filters.length > 0) && (
                <Col xs="auto">
                  <Dropdown
                    align="end"
                    className="dropdown-card"
                  >
                    <Dropdown.Toggle bsPrefix="btn btn-white btn-sm">
                      <Sliders
                        className="me-1"
                        size="1em"
                      /> Filter
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Card.Header>
                        <h4 className="card-header-title">Filters</h4>
                      </Card.Header>

                      <Card.Body>
                        <ListGroup className="list-group-flush mt-n4 mb-4">
                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <small>Title</small>
                              </Col>

                              <Col xs="auto">
                                {/* <Select options={titleOptions} size="sm" /> */}
                              </Col>
                            </Row>
                          </ListGroup.Item>

                          <ListGroup.Item>
                            <Row>
                              <Col>
                                <small>Lead score</small>
                              </Col>

                              <Col xs="auto">
                                {/* <Select options={leadScoreOptions} size="sm" /> */}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        </ListGroup>

                        <Button className="w-100">Apply filter</Button>
                      </Card.Body>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              ) }
            </Row>
          </Card.Header>
        ) }
        
        <Table
          size="sm"
          className="card-table table-nowrap"
          hover
          responsive
        >
          <thead>
            <tr>
              { columns.map(column => (
                <th 
                  key={column.key}
                  className={classNames(column.headerClass, column.sortable && 'cursor-pointer')}
                  onClick={() => column.sortable ? toggleSort(column.key) : {}}
                >
                  <span className={classNames('d-flex flex-row align-items-center', column.sortable && 'is-sortable', sortBy === column.key && (sortDir === 'asc' ? 'asc' : 'desc'))}>
                    { column.header }
                  </span>
                </th>
              )) }

              { actions.length > 0 && <th className="min-width"></th> }
            </tr>
          </thead>

          <tbody
            className="fs-base"
          >
            { (!busy && data.length > 0) && (
              data.map((row: {[key: string]: any}) => (
                <tr key={row.id}>
                  { columns.map(column => (
                    <td
                      key={`${row.id}-${column.key}`}
                      className={column.rowClass}
                    >
                      { column.accessor !== undefined && row[column.accessor] }

                      { column.render !== undefined && column.render(row) }
                    </td>
                  )) }

                  { actions.length > 0 && (
                    <td className="d-flex flex-row justify-content-end">
                      { actions.map(action => (
                        <div key={`${row.id}-action-${action.key}`}>
                          { action.render(row) }
                        </div>
                      )) }
                    </td>
                  ) }
                </tr>
              ))
            ) }

            { busy && (
              <tr>
                <td
                  colSpan={columns.length + (actions.length > 0 ? 1 : 0)}
                >
                  <div className="d-flex flex-row align-items-center justify-content-center my-5 py-5">
                    <Spinner
                      animation="border"
                      className="me-3"
                      style={{ width: '20px', height: '20px', opacity: 0.75 }}
                    /> 
                  
                    Loading...
                  </div>
                </td>
              </tr>
            ) }

            { (!busy && data.length === 0) && (
              <tr>
                <td
                  colSpan={columns.length + (actions.length > 0 ? 1 : 0)}
                >
                  <div className="d-flex flex-row align-items-center justify-content-center my-5 py-5">
                    No results found.
                  </div>
                </td>
              </tr>
            ) }
          </tbody>
        </Table>

        { (!busy && data.length > 0) && (
          <Card.Footer className="d-flex justify-content-between">
            <Pagination className="card-pagination pagination-tabs">
              <Pagination.Item
                className="ps-0 pe-4 border-end"
                disabled={page === 1}
                onClick={() => (page > 1 ? setPage(page-1) : {})}
              >
                <ArrowLeft
                  size="1em"
                  className="me-1"
                /> Prev
              </Pagination.Item>
            </Pagination>

            <Pagination className="card-pagination pagination-tabs">
              {shownPages.map((shownPage, pageIdx) => (
                <>
                  { (pageIdx > 0 && shownPage > shownPages[pageIdx-1]+1) && (
                    <Pagination.Ellipsis key={shownPage} />
                  ) }

                  <Pagination.Item
                    key={shownPage}
                    active={shownPage === page}
                    onClick={() => setPage(shownPage)}
                  >
                    {shownPage}
                  </Pagination.Item>
                </>
              ))}
            </Pagination>

            <Pagination className="card-pagination pagination-tabs">
              <Pagination.Item
                className="ps-4 pe-0 border-start"
                disabled={page === lastPage}
                onClick={() => (page < lastPage ? setPage(page+1) : {})}
              >
                Next 
                <ArrowRight
                  size="1em"
                  className="ms-1"
                />
              </Pagination.Item>
            </Pagination>
          </Card.Footer>
        ) }
      </Card>

      {/* {Object.keys(selectedRowIds).length > 0 && (
        <Alert variant="dark" className="list-alert alert-dismissible border">
          <Row className="align-items-center">
            <Col>
              <Form.Check type="checkbox" label={`${Object.keys(selectedRowIds).length} deal(s)`} checked disabled />
            </Col>
            <Col xs="auto" className="me-n3">
              <Button variant="white-20" size="sm">
                Edit
              </Button>
              <Button variant="white-20" size="sm" className="ms-1">
                Delete
              </Button>
            </Col>
          </Row>
          <CloseButton />
        </Alert>
      )} */}
    </>
  )
}

import { ErrorMessage } from 'formik'
import { Col, InputGroup, Row } from 'react-bootstrap'
import { Minus, MinusCircle, Trash } from 'react-feather'

import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRowEdit from '../components/ResourceDetailRowEdit'
import Country from '../components/fields/Country'
import Currency from '../components/fields/Currency'
import Select from '../components/fields/Select'
import Text from '../components/fields/Text'
import Textarea from '../components/fields/Textarea'

import ResourceEdit from './generic/ResourceEdit'

export default function OfferingEdit() {
  return (
    <ResourceEdit
      name={{ singular: 'offering', plural: 'offerings' }}
    >
      { (offering, helpers) => (
        <>
          <ResourceDetailGroup 
            title="Offering details"
          >
            <ResourceDetailRowEdit label="Name">
              <Text
                name="name"
                label="Name"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Type">
              <Select
                name="meta.type"
                label="Type"
                isSearchable={false}
                options={[
                  { label: 'Individual property', value: 'individual' },
                  { label: 'Portfolio', value: 'portfolio' }
                ]}
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Status">
              <Select
                name="status"
                label="Status"
                isSearchable={false}
                options={[
                  { label: 'Open', value: 'open' },
                  { label: 'Closed', value: 'closed' },
                  { label: 'Future', value: 'future' }
                ]}
              />
            </ResourceDetailRowEdit>
            
            <ResourceDetailRowEdit label="Total Investment Offering">
              <Currency
                name="amount"
                label="Amount"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Description">
              <Textarea
                name="description"
                label="Description"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Crop">
              <Text
                name="meta.crop"
                label="Crop"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Location">
              <Text
                name="location"
                label="Location"
              />
            </ResourceDetailRowEdit>
            
            <ResourceDetailRowEdit label="Country">
              <Country
                name="country"
                label="Country"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Area">
              <Text
                name="meta.area"
                label="Area"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Project Operator">
              <Text
                name="meta.project_operator"
                label="Project Operator"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Expected Duration">
              <Text
                name="meta.expected_duration"
                label="Expected Duration"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Farmland">
              <Text
                name="meta.farmland"
                label="Farmland"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Productive Area">
              <Text
                name="meta.productive_area"
                label="Productive Area"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Gross Cash Yield">
              <Text
                name="meta.yield"
                label="Gross Cash Yield"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Net Anual IRR">
              <Text
                name="meta.irr"
                label="Net Anual IRR"
              />
            </ResourceDetailRowEdit>

            <ResourceDetailRowEdit label="Sustainability points">
              { (helpers.values.meta.sustainability ?? []).map((point: string, idx: number) => (
                <Row
                  key={idx}
                  className="mb-2"
                >
                  <Col>
                    <Text
                      name={`meta.sustainability.${idx}`}
                      label={`Sustainability point ${(idx+1)}`}
                    />
                  </Col>

                  <Col
                    xs="auto"
                    className="ps-0"
                  >
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => helpers.setFieldValue('meta.sustainability', helpers.values.meta.sustainability.filter((val: any, itemIdx: number) => itemIdx !== idx))}
                    >
                      Remove
                    </button>
                  </Col>
                </Row>
              )) }

              <button
                type="button"
                className="btn btn-success"
                onClick={() => helpers.setFieldValue('meta.sustainability', [...helpers.values.meta.sustainability, ''])}
              >
                Add sustainability point
              </button>
            </ResourceDetailRowEdit>
          </ResourceDetailGroup>
        </>
      ) }
    </ResourceEdit>
  )
}

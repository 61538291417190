import { ArrowUpRight } from 'react-feather'

export default function ResourceDetailExternalAction({ title, href }: { title: string, href: string }) {
  return (
    <div className="float-right">
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="d-flex flex-row align-items-center"
      >
        { title }

        <ArrowUpRight
          size="16px"
          className="ms-2"
        />
      </a>
    </div>
  )
}

import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { Briefcase, UserPlus } from 'react-feather'
import { toast } from 'react-toastify'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'
import ResourceDetailGroup from '../ResourceDetailGroup'
import ResourceDetailRowEdit from '../ResourceDetailRowEdit'
import BelongsTo from '../fields/BelongsTo'
import Select from '../fields/Select'

interface InvestorUserAttachProps extends DropdownItemProps { 
  parent: 'investor'|'user',
  parentId: number,
  onSuccess: () => void
}

enum Status {
  ShowButton,
  ShowModal,
}

export default function InvestorUserAttach({ parent, parentId, onSuccess, ...props }: InvestorUserAttachProps) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => setStatus(Status.ShowModal)}
        {...props}
      >
        { parent === 'user' && (
          <Briefcase
            size="14px"
            className="me-3"
          /> 
        ) }

        { parent === 'investor' && (
          <UserPlus
            size="14px"
            className="me-3"
          /> 
        ) }

        Attach {parent === 'user' ? 'an investor' : 'a user'}
      </Dropdown.Item>

      <Modal
        show={status === Status.ShowModal}
        onHide={() => setStatus(Status.ShowButton)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Attach {parent === 'user' ? 'an investor' : 'a user'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={
              parent === 'user'
                ? { user_id: parentId, investor_id: '', investor_name: '', type: '' }
                : { investor_id: parentId, user_id: '', user_name: '', type: '' }
            }
            onSubmit={async (values, actions) => {
              try {
                const response = await api.post('/investor_user', values, { signal: abortController.signal })

                if (response !== undefined) {
                  toast.success((parent === 'user' ? 'Investor' : 'User') + ' attached successfully.')
                  setStatus(Status.ShowButton)
                  onSuccess()
                }
              }
              catch (err) {
                if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                  actions.setErrors(err.response.data.errors)
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                {/* <p className="mb-3 pb-1">
                  Please select the {parent === 'user' ? 'investor' : 'user'} you want to attach to this {parent}. This entity will be linked with an initial authority status of <b>Not verified</b>.
                </p> */}

                <ResourceDetailGroup>
                  <ResourceDetailRowEdit label={`Related ${parent === 'user' ? 'investor' : 'user'}`}>
                    <BelongsTo
                      name={parent === 'user' ? 'investor_id' : 'user_id'}
                      labelName={parent === 'user' ? 'investor_name' : 'user_name'}
                      label={parent === 'user' ? 'Search an investor...' : 'Search a user...'}
                      endpoint={parent === 'user' ? '/investors' : '/users'}
                    />
                  </ResourceDetailRowEdit>

                  <ResourceDetailRowEdit label="Relationship">
                    <Select
                      name="type"
                      label="Relationship"
                      isSearchable={false}
                      options={[
                        { label: 'Legal representative', value: 'legal_representative' }
                      ]}
                    />
                  </ResourceDetailRowEdit>
                </ResourceDetailGroup>

                <p className="text-right mb-0">
                  <Button
                    variant="link"
                    className="text-secondary me-2"
                    onClick={() => setStatus(Status.ShowButton)}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    type="submit"
                    busy={isSubmitting}
                    variant="info"
                    className="px-4"
                  >
                    Attach {parent === 'user' ? 'investor' : 'user'}
                  </LoadingButton>
                </p>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

import { Dropdown } from 'react-bootstrap'
import { Edit2, UserCheck } from 'react-feather'
import { Link } from 'react-router-dom'

import ResourceDetailExternalAction from '../components/ResourceDetailExternalAction'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailHeading from '../components/ResourceDetailHeading'
import ResourceDetailRow from '../components/ResourceDetailRow'
import ResourceTable from '../components/ResourceTable'
import InvestorUserAttach from '../components/actions/InvestorUserAttach'
import InvestorUserReview from '../components/actions/InvestorUserReview'
import KycStatus from '../components/columns/KycStatus'
import { countryName } from '../helpers'

import ResourceShow from './generic/ResourceShow'

enum Views {
  Info = 'info',
  Investors = 'investors'
}

export default function UserShow() {
  return (
    <ResourceShow
      name={{ singular: 'user', plural: 'users' }}
      getResourceName={(user) => `${user.first_name} ${user.last_name}`}
      views={[
        {
          label: 'Information',
          value: Views.Info,
        },
        {
          label: 'Investors',
          value: Views.Investors,
        },
      ]}
      options={(user, helpers) => (
        <>
          <Link to={`/users/${user.id}/edit`}>
            <Dropdown.Item
              as="span" 
              className="d-flex align-items-center"
            >
              <Edit2
                size="14px"
                className="me-3"
              /> Edit basic details
            </Dropdown.Item>
          </Link>

          <InvestorUserAttach
            parent="user"
            parentId={user.id}
            onSuccess={() => { helpers.loadResource(); helpers.setView(Views.Investors) }}
          />
        </>
      )}
    >
      { (user, view, helpers) => (
        <>
          { view === Views.Info && (
            <>
              <ResourceDetailGroup 
                title="Identification details"
              >
                <ResourceDetailRow label="First name">
                  { user.first_name }
                </ResourceDetailRow>

                <ResourceDetailRow label="Last name">
                  { user.last_name }
                </ResourceDetailRow>

                <ResourceDetailRow label="Birth date">
                  { user.birth_date ?? '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Place of birth">
                  { user.birth_place ?? '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Country of birth">
                  { countryName(user.birth_country ?? '—') }
                </ResourceDetailRow>

                <ResourceDetailRow label="Nationality">
                  { countryName(user.nationality ?? '—') }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Verification"
              >
                <ResourceDetailRow label="KYC status">
                  <KycStatus value={user.kyc_status} />
                </ResourceDetailRow>

                <ResourceDetailRow label="Sumsub applicant ID">
                  { user.sumsub_applicant_id && (
                    <ResourceDetailExternalAction
                      title="View on Sumsub"
                      href={`https://cockpit.sumsub.com/checkus#/applicant/${ user.sumsub_applicant_id }/basicInfo?clientId=bageera.co_35223`}
                    />
                  ) }

                  { user.sumsub_applicant_id ?? '—' }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Residence details"
              >
                <ResourceDetailRow label="Address Line 1">
                  { user.address_line1 }
                </ResourceDetailRow>

                <ResourceDetailRow label="Address Line 2">
                  { user.address_line2 || '–' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Postal code">
                  { user.address_postal_code }
                </ResourceDetailRow>

                <ResourceDetailRow label="City">
                  { user.address_city }
                </ResourceDetailRow>

                <ResourceDetailRow label="Country of residence">
                  { countryName(user.country) }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Contact details"
              >
                <ResourceDetailRow label="Email address">
                  <ResourceDetailExternalAction
                    title="Send email"
                    href={`mailto:${ user.email }`}
                  />

                  { user.email }
                </ResourceDetailRow>

                <ResourceDetailRow label="Phone number">
                  { user.phone && (
                    <ResourceDetailExternalAction
                      title="Call"
                      href={`tel:${ user.phone }`}
                    /> 
                  ) }

                  { user.phone }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Proof of identity details"
              >
                <ResourceDetailRow label="Document type">
                  { user.document_type === 'id_card' && 'ID card' }

                  { user.document_type === 'passport' && 'Passport' }

                  { user.document_type === 'residence_permit' && 'Residence permit' }

                  { !['id_card', 'passport', 'residence_permit'].includes(user.document_type) && '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Document number">
                  { user.document_number ?? '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Document expiry date">
                  { user.document_expiry ?? '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Document country">
                  { countryName(user.document_country ?? '—') }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Authentication"
              >
                <ResourceDetailRow label="Auth0 sub">
                  { user.sub !== null && (
                    <ResourceDetailExternalAction
                      title="Search in Auth0"
                      href="https://manage.auth0.com/dashboard/eu/bageera/users"
                    />
                  ) }

                  { user.sub ?? '—' }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Others"
              >
                <ResourceDetailRow label="Estimated investment range">
                  { user.investment_range ?? '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Where did you hear from us?">
                  { user.heard_from ?? '—' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Email verified">
                  { user.email_verified ? 'Yes' : 'No' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Passed welcome">
                  { user.passed_welcome ? 'Yes' : 'No' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Joined waitlist">
                  { user.joined_waitlist ? 'Yes' : 'No' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Creation date">
                  { user.created_at }
                </ResourceDetailRow>

                <ResourceDetailRow label="Last update date">
                  { user.updated_at }
                </ResourceDetailRow>
              </ResourceDetailGroup>
            </>
          ) }

          { view === Views.Investors && (
            <>
              <ResourceDetailHeading>
                Related investors
              </ResourceDetailHeading>

              <ResourceTable
                endpoint={`/users/${user.id}/investors`}
                columns={[
                  {
                    key: 'name',
                    header: 'Name',
                    accessor: 'name'
                  },
                  {
                    key: 'type',
                    header: 'Type',
                    render: (row: { [key: string]: any }) => (
                      <>
                        { row.type === 'natural' && 'Natural person' }
              
                        { row.type === 'legal' && 'Legal entity' }
                      </>
                    )
                  },
                  {
                    key: 'country',
                    header: 'Country',
                    render: (row: { [key: string]: any }) => countryName(row.country)
                  },
                  {
                    key: 'kyc_status',
                    header: 'KYC',
                    headerClass: 'min-width',
                    rowClass: 'text-center',
                    render: (row: { [key: string]: any }) => <KycStatus value={row.kyc_status} />
                  },
                  {
                    key: 'authority',
                    header: 'Authority',
                    headerClass: 'min-width',
                    rowClass: 'text-center',
                    render: (row: { [key: string]: any }) => <KycStatus value={row.authority_status} />
                  },
                ]}
                actions={[
                  {
                    key: 'view',
                    render: (row: { [key: string]: any }) => (
                      <InvestorUserReview
                        parent="user"
                        pivotId={row.pivot_id}
                        onSuccess={() => { helpers.loadResource(); helpers.setView(Views.Investors) }}
                      />
                    )
                  },
                ]}
              />
            </>
          ) }
        </>
      ) }
    </ResourceShow>
  )
}

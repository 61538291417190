import { useMemo, useState } from 'react'
import { Container } from 'react-bootstrap'
import { ArrowUpRight } from 'react-feather'
import { Link } from 'react-router-dom'

import HeaderTabs from '../components/HeaderTabs'
import ResourceTable from '../components/ResourceTable'
import { currencyFormat } from '../helpers'

export default function InvestmentIndex() {
  const [status, setStatus] = useState<string|null>(null)

  const columns = useMemo(() => [
    {
      key: 'investor',
      header: 'Investor',
      headerClass: 'col-3',
      rowClass: 'overflow-ellipsis',
      render: (row: { [key: string]: any }) => (
        <>
          { row.investor_name }

          <Link
            to={`/investors/${row.investor_id}`}
          >
            <ArrowUpRight
              size="16px"
              className="ms-2"
            />
          </Link>
        </>
      )
    },
    {
      key: 'offering',
      header: 'Offering',
      headerClass: 'col-3',
      rowClass: 'overflow-ellipsis',
      render: (row: { [key: string]: any }) => (
        <>
          { row.offering_name }

          <Link
            to={`/offerings/${row.offering_id}`}
          >
            <ArrowUpRight
              size="16px"
              className="ms-2"
            />
          </Link>
        </>
      )
    },
    {
      key: 'amount',
      header: 'Amount',
      render: (row: { [key: string]: any }) => currencyFormat(row.amount, row.currency),
      sortable: true
    },
    {
      key: 'status',
      header: 'Status',
      render: (row: { [key: string]: any }) => row.status[0].toUpperCase() + row.status.substring(1),
    },
    {
      key: 'updated_at',
      header: 'Updated',
      accessor: 'updated_at',
      sortable: true
    }
  ], [])
  
  const actions = useMemo(() => [
    {
      key: 'view',
      render: (row: { [key: string]: any }) => (
        <Link
          to={`/investments/${row.id}`}
          className="btn btn-sm btn-primary"
        >
          View
        </Link>
      )
    }
  ], [])

  return (
    <>
      <div className="header mt-2">
        <Container fluid>
          <div className="header-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h1 className="header-title">
                Investments
              </h1>

              <Link
                to="/investments/create"
                className="btn btn-success d-flex align-items-center"
              >
                Create investment
              </Link>
            </div>

            <HeaderTabs
              tabs={[
                {
                  label: 'All investments',
                  value: null,
                },
                {
                  label: 'Created',
                  value: 'created',
                },
                {
                  label: 'Prepared',
                  value: 'prepared',
                },
                {
                  label: 'Reviewed',
                  value: 'reviewed',
                },
                {
                  label: 'Signed',
                  value: 'signed',
                },
                {
                  label: 'Paid',
                  value: 'paid',
                },
                {
                  label: 'Declined',
                  value: 'declined',
                },
              ]}
              value={status}
              onChange={value => setStatus(value)}
            />
          </div>
        </Container>
      </div>

      <Container
        fluid
        className="flex-auto d-flex flex-column"
      >
        <ResourceTable
          endpoint="/investments"
          columns={columns}
          actions={actions}
          searchable={true}
          initialSortBy={{ key: 'updated_at', dir: 'desc' }}
          externalParamValues={{ 'status': status }}
        />
      </Container>
    </>
  )
}

import { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { Trash } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'

interface ResourceDeleteProps extends DropdownItemProps {
  resourceName: string;
  endpoint: string;
  redirectTo: string;
  disabled?: boolean;
}

enum Status {
  ShowButton,
  ShowModal,
  Deleting
}

export default function ResourceDelete({ resourceName, endpoint, redirectTo, disabled = false, ...props }: ResourceDeleteProps) {
  const abortController = new AbortController()

  const navigate = useNavigate()
  const [status, setStatus] = useState<Status>(Status.ShowButton)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => setStatus(Status.ShowModal)}
        disabled={disabled}
        {...props}
      >
        <Trash
          size="14px"
          className="me-3"
        /> Delete {resourceName}
      </Dropdown.Item>

      <Modal
        show={status === Status.ShowModal || status === Status.Deleting}
        onHide={() => setStatus(Status.ShowButton)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Delete {resourceName}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="mb-4">
            Are you sure you want to delete this {resourceName}? This action cannot be undone.
          </p>

          <p className="text-right mb-0">
            <Button
              variant="link"
              className="text-secondary me-2"
              onClick={() => setStatus(Status.ShowButton)}
            >
              Cancel
            </Button>

            <LoadingButton
              busy={status === Status.Deleting}
              variant="danger"
              onClick={() => {
                setStatus(Status.Deleting)

                api.delete(endpoint, { signal: abortController.signal })
                  .then(response => {
                    if (response !== undefined) {
                      toast.success(resourceName[0].toUpperCase() + resourceName.substring(1) + ' deleted successfully.')
                      navigate(redirectTo, { state: { afterDelete: true } })
                    }
                  })
                  .catch(err => {
                    setStatus(Status.ShowModal)
                  })
              }}
            >
              Delete {resourceName}
            </LoadingButton>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

import { ReactNode } from 'react'
import { Card, ListGroup } from 'react-bootstrap'

import ResourceDetailHeading from './ResourceDetailHeading'

export default function ResourceDetailGroup({ title, children }: { title?: string, children: ReactNode }) {
  return (
    <>
      { title && (
        <ResourceDetailHeading>
          { title }
        </ResourceDetailHeading>
      ) }

      <Card>
        <Card.Body className="p-0">
          <ListGroup className="list-group-flush">
            { children }
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  )
}

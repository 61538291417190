import { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { RefreshCw } from 'react-feather'
import { toast } from 'react-toastify'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'

interface InvestmentRegenerateAgreementProps extends DropdownItemProps {
  investmentId: number;
  onSuccess: (updatedInvestment: { [key: string]: any }) => void;
  disabled?: boolean;
}

enum Status {
  ShowButton,
  ShowModal,
  Loading
}

export default function InvestmentRegenerateAgreement({ investmentId, onSuccess, disabled = false, ...props }: InvestmentRegenerateAgreementProps) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => setStatus(Status.ShowModal)}
        disabled={disabled}
        {...props}
      >
        <RefreshCw
          size="14px"
          className="me-3"
        /> Regenerate agreement
      </Dropdown.Item>

      <Modal
        show={status === Status.ShowModal || status === Status.Loading}
        onHide={() => setStatus(Status.ShowButton)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Regenerate agreement
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="mb-4">
            Are you sure you want to regenerate the agreement for this investment? This will override the current document with updated information.
          </p>

          <p className="text-right mb-0">
            <Button
              variant="link"
              className="text-secondary me-2"
              onClick={() => setStatus(Status.ShowButton)}
            >
              Cancel
            </Button>

            <LoadingButton
              busy={status === Status.Loading}
              variant="info"
              onClick={() => {
                setStatus(Status.Loading)

                api.post(`/investments/${investmentId}/regenerate`, {}, { signal: abortController.signal })
                  .then(response => {
                    if (response !== undefined) {
                      toast.success('The agreement has been updated.')
                      setStatus(Status.ShowButton)
                      onSuccess(response.data.data.investment)
                    }
                  })
                  .catch(err => {
                    setStatus(Status.ShowModal)
                  })
              }}
            >
              Regenerate agreement
            </LoadingButton>
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

import { useEffect, useState } from 'react'
import { Dropdown, Modal, Spinner } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { HelpCircle } from 'react-feather'
import { toast } from 'react-toastify'

import api from '../../utils/api'

interface InvestmentUpdateSignatureStatusProps extends DropdownItemProps {
  investmentId: number;
  onSuccess: (updatedInvestment: { [key: string]: any }) => void;
  disabled?: boolean;
}

enum Status {
  ShowButton,
  Loading
}

export default function InvestmentUpdateSignatureStatus({ investmentId, onSuccess, disabled = false, ...props }: InvestmentUpdateSignatureStatusProps) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => {
          setStatus(Status.Loading)

          api.post(`/investments/${investmentId}/signature_status`, {}, { signal: abortController.signal })
            .then(response => {
              if (response !== undefined) {
                setStatus(Status.ShowButton)

                if (response.data.data.investment.status === 'signed') {
                  toast.success('Investment has been signed.')
                } else {
                  toast.info('Investment status not changed.')
                }

                onSuccess(response.data.data.investment)
              }
            })
            .catch(err => {
              setStatus(Status.ShowButton)
            })
        }}
        disabled={disabled || status === Status.Loading}
        {...props}
      >
        <HelpCircle
          size="14px"
          className="me-3"
        /> Update signature status
      </Dropdown.Item>

      <Modal
        show={status === Status.Loading}
        centered
        size="sm"
      >
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <Spinner
            animation="border"
            size="sm"
            className="me-3"
          /> Updating signature status...
        </Modal.Body>
      </Modal>
    </>
  )
}

import { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { Edit3 } from 'react-feather'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'

interface InvestmentGetSignatureLinkProps extends DropdownItemProps {
  investmentId: number;
  onSuccess: (updatedInvestment: { [key: string]: any }) => void;
  disabled?: boolean;
}

enum Status {
  ShowButton,
  ShowIntro,
  Loading,
  ShowResult
}

export default function InvestmentGetSignatureLink({ investmentId, onSuccess, disabled = false, ...props }: InvestmentGetSignatureLinkProps) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)
  const [signatureUrl, setSignatureUrl] = useState<string>('')

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => setStatus(Status.ShowIntro)}
        disabled={disabled}
        {...props}
      >
        <Edit3
          size="14px"
          className="me-3"
        /> Get signature link for investor
      </Dropdown.Item>

      <Modal
        show={status === Status.ShowIntro || status === Status.Loading}
        onHide={() => setStatus(Status.ShowButton)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Get signature link for investor
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to generate a signature link for this investment? Please review the generated agreement document before proceeding.
          </p>

          <p className="mb-4">
            If this is the first time you generate a signature link, this will mark the selected investment as reviewed and will create a DocuSign envelope for it.
          </p>

          <p className="text-right mb-0">
            <Button
              variant="link"
              className="text-secondary me-2"
              onClick={() => setStatus(Status.ShowButton)}
            >
              Cancel
            </Button>

            <LoadingButton
              busy={status === Status.Loading}
              variant="info"
              onClick={() => {
                setStatus(Status.Loading)

                api.post(`/investments/${investmentId}/signature_link`, {}, { signal: abortController.signal })
                  .then(response => {
                    if (response !== undefined) {
                      setSignatureUrl(response.data.data.signature_url)
                      setStatus(Status.ShowResult)
                      onSuccess(response.data.data.investment)
                    }
                  })
                  .catch(err => {
                    setStatus(Status.ShowIntro)
                  })
              }}
            >
              Get signature link
            </LoadingButton>
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={status === Status.ShowResult}
        onHide={() => setStatus(Status.ShowButton)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Signature link for investor
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="mb-4">
            The investment has been marked as reviewed and has been sent to DocuSign. You can now share the following link to the investor so they can electronically sign the investment agreement via DocuSign:
          </p>

          <p className="mb-4">
            <input
              type="text"
              className="form-control"
              readOnly
              value={signatureUrl}
            />
          </p>

          <p className="text-right mb-0">
            <Button
              variant="outline-info"
              onClick={() => setStatus(Status.ShowButton)}
            >
              Close
            </Button>

            {/* <LoadingButton
              busy={status === Status.Loading}
              variant="info"
              onClick={() => {
                setStatus(Status.Loading)

                api.post(`/investments/${investmentId}/regenerate`, {}, { signal: abortController.signal })
                  .then(response => {
                    if (response !== undefined) {
                      toast.success('The agreement has been updated.')
                      setSignatureUrl(response.data.data.signature_url)
                      setStatus(Status.ShowResult)
                    }
                  })
                  .catch(err => {
                    setStatus(Status.ShowIntro)
                  })
              }}
            >
              Get signature link
            </LoadingButton> */}
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}

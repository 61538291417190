import ResourceDetailExternalAction from '../components/ResourceDetailExternalAction'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRow from '../components/ResourceDetailRow'
import ResourceDetailRowEdit from '../components/ResourceDetailRowEdit'
import KycStatus from '../components/columns/KycStatus'
import Country from '../components/fields/Country'
import Text from '../components/fields/Text'
import { countryName } from '../helpers'

import ResourceEdit from './generic/ResourceEdit'

export default function UserEdit() {
  return (
    <ResourceEdit
      name={{ singular: 'user', plural: 'users' }}
      getResourceName={user => `${user.first_name} ${user.last_name}`}
    >
      { user => (
        <>
          <ResourceDetailGroup 
            title="Identification details"
          >
            <ResourceDetailRow label="First name">
              { user.first_name }
            </ResourceDetailRow>

            <ResourceDetailRow label="Last name">
              { user.last_name }
            </ResourceDetailRow>

            <ResourceDetailRow label="Birth date">
              { user.birth_date ?? '—' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Nationality">
              { countryName(user.nationality ?? '—') }
            </ResourceDetailRow>
          </ResourceDetailGroup>

          <ResourceDetailGroup 
            title="Residence details"
          >
            <ResourceDetailRowEdit label="Country of residence">
              <Country
                name="country"
                label="Country of residence"
              />
            </ResourceDetailRowEdit>
          </ResourceDetailGroup>

          <ResourceDetailGroup 
            title="Contact details"
          >
            <ResourceDetailRow label="Email address">
              <ResourceDetailExternalAction
                title="Send email"
                href={`mailto:${ user.email }`}
              />

              { user.email }
            </ResourceDetailRow>

            <ResourceDetailRowEdit label="Phone number (with prefix)">
              <Text
                name="phone"
                label="Phone number"
              />
            </ResourceDetailRowEdit>
          </ResourceDetailGroup>

          <ResourceDetailGroup 
            title="Verification"
          >
            <ResourceDetailRow label="KYC status">
              <KycStatus value={user.kyc_status} />
            </ResourceDetailRow>

            <ResourceDetailRow label="Sumsub applicant ID">
              { user.sumsub_applicant_id && (
                <ResourceDetailExternalAction
                  title="View on Sumsub"
                  href={`https://cockpit.sumsub.com/checkus#/applicant/${ user.sumsub_applicant_id }/basicInfo?clientId=bageera.co_35223`}
                />
              ) }

              { user.sumsub_applicant_id ?? '—' }
            </ResourceDetailRow>
          </ResourceDetailGroup>

          <ResourceDetailGroup 
            title="Proof of identity details"
          >
            <ResourceDetailRow label="Document type">
              { user.document_type === 'id_card' && 'ID card' }

              { user.document_type === 'passport' && 'Passport' }

              { user.document_type === 'residence_permit' && 'Residence permit' }

              { !['id_card', 'passport', 'residence_permit'].includes(user.document_type) && '—' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Document number">
              { user.document_number ?? '—' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Document expiry date">
              { user.document_expiry ?? '—' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Document country">
              { countryName(user.document_country ?? '—') }
            </ResourceDetailRow>
          </ResourceDetailGroup>

          <ResourceDetailGroup 
            title="Authentication"
          >
            <ResourceDetailRow label="Auth0 sub">
              { user.sub !== null && (
                <ResourceDetailExternalAction
                  title="Search in Auth0"
                  href="https://manage.auth0.com/dashboard/eu/bageera/users"
                />
              ) }

              { user.sub ?? '—' }
            </ResourceDetailRow>
          </ResourceDetailGroup>

          <ResourceDetailGroup 
            title="Others"
          >
            <ResourceDetailRow label="Estimated investment range">
              { user.investment_range ?? '—' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Where did you hear from us?">
              { user.heard_from ?? '—' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Email verified">
              { user.email_verified ? 'Yes' : 'No' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Passed welcome">
              { user.passed_welcome ? 'Yes' : 'No' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Joined waitlist">
              { user.joined_waitlist ? 'Yes' : 'No' }
            </ResourceDetailRow>

            <ResourceDetailRow label="Creation date">
              { user.created_at }
            </ResourceDetailRow>

            <ResourceDetailRow label="Last update date">
              { user.updated_at }
            </ResourceDetailRow>
          </ResourceDetailGroup>
        </>
      ) }
    </ResourceEdit>
  )
}

import axios from 'axios'
import { Form, Formik } from 'formik'
import { ReactNode, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { ArrowLeft } from 'react-feather'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import LoadingButton from '../../components/LoadingButton'
import api from '../../utils/api'
import Loading from '../aux/Loading'

interface ResourceType {
  [key: string]: any;
}

export interface ResourceEditProps {
  name: {
    singular: string;
    plural: string;
  };
  getResourceName?: (resource: ResourceType) => string;
  children: (resource: ResourceType, helpers: { [key: string]: any }) => ReactNode;
}

export default function ResourceEdit({ name, getResourceName = resource => resource.name, children }: ResourceEditProps) {
  const abortController = new AbortController()

  const { id } = useParams()
  const navigate = useNavigate()

  const [resource, setResource] = useState<{[key: string]: any}|null>(null)

  useEffect(() => {
    api.get(`/${name.plural}/${id}`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setResource(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [id])

  if (resource === null) {
    return <Loading />
  }

  return (
    <>
      <Container fluid>
        <div className="header mt-2">
          <div className="header-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    to={`/${name.plural}`}
                    className="text-muted"
                  >
                    { name.plural.charAt(0).toUpperCase() + name.plural.substr(1) }
                  </Link>
                </li>

                <li className="breadcrumb-item text-primary">
                  <Link
                    to={`/${name.plural}/${id}`}
                    className="text-muted"
                  >
                    { getResourceName(resource) }
                  </Link>
                </li>

                <li className="breadcrumb-item text-primary">
                  Edit
                </li>
              </ol>

              <div className="d-flex">
                <Link
                  to={`/${name.plural}/${id}`}
                  className="btn btn-link text-secondary me-2"
                >
                  <ArrowLeft
                    size="14px"
                    className="me-2"
                  />

                  Back to {name.singular}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Formik
          initialValues={resource}
          onSubmit={async (values, actions) => {
            try {
              const response = await api.put(`/${name.plural}/${id}`, values, { signal: abortController.signal })

              if (response !== undefined) {
                toast.success(name.singular.charAt(0).toUpperCase() + name.singular.substring(1) + ' updated successfully.')
                navigate(`/${name.plural}/${id}`)
              }
            }
            catch (err) {
              if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                actions.setErrors(err.response.data.errors)
              }
            }
          }}
        >
          { (helpers) => (
            <Form>
              { children(resource, helpers) }

              <div className="d-flex justify-content-end">
                <LoadingButton
                  busy={helpers.isSubmitting}
                  type="submit"
                  className="btn btn-info px-5 mb-5"
                >
                  Update {name.singular}
                </LoadingButton>
              </div>
            </Form>
          ) }
        </Formik>
      </Container>
    </>
  )
}

import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { ArrowLeft } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import LoadingButton from '../components/LoadingButton'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRowEdit from '../components/ResourceDetailRowEdit'
import Select from '../components/fields/Select'
import Text from '../components/fields/Text'
import api from '../utils/api'

export default function OpportunityCreate() {
  const abortController = new AbortController()

  const navigate = useNavigate()

  const [locationOptions, setLocationOptions] = useState<{[key: string]: {label: string, value: string|number}[]}>({
    provinces: [],
    municipalities: [],
    areas: [],
    plots: [],
  })

  const loadLocationOptions = async (localType: string, path: string) => {
    setLocationOptions({ ...locationOptions, [localType]: [] })

    const response = await axios.get(`https://sigpac.mapa.es/fega/ServiciosVisorSigpac/query/${path}.geojson`)

    setLocationOptions({ ...locationOptions, [localType]: [
      { label: '', value: '' },
      ...response.data.features.map((feature: {[key: string]: any}) => ({
        label: feature.properties.nombre,
        value: feature.properties.codigo
      }))
    ]})
  }

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Container fluid>
        <Formik
          initialValues={{
            name: '',
            country: '',
            location: {
              region: '',
              province: '',
              municipality: '',
              area: '',
              plot: '',
            },
          }}
          onSubmit={async (values, actions) => {
            try {
              const response = await api.post('/opportunities', values, { signal: abortController.signal })

              if (response !== undefined) {
                toast.success('Opportunity created successfully.')
                navigate(`/opportunities/${response.data.data.id}`)
              }
            }
            catch (err) {
              if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                actions.setErrors(err.response.data.errors)
              }
            }
          }}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <>
              <div className="header mt-2">
                <div className="header-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link
                          to="/opportunities"
                          className="text-muted"
                        >
                          Opportunities
                        </Link>
                      </li>

                      <li className="breadcrumb-item text-primary">
                        Create opportunity
                      </li>
                    </ol>

                    <Link
                      to="/opportunities"
                      className="btn btn-link text-secondary"
                    >
                      <ArrowLeft
                        size="14px"
                        className="me-2"
                      />
                      Back to list
                    </Link>
                  </div>
                </div>
              </div>

              <Form>
                <ResourceDetailGroup 
                  title="Identification details"
                >
                  <ResourceDetailRowEdit label="Name">
                    <Text
                      name="name"
                      label="Name"
                    />
                  </ResourceDetailRowEdit>

                  <ResourceDetailRowEdit label="Country">
                    <Select
                      name="country"
                      label="Country"
                      options={[
                        { label: 'Spain', value: 'ES' }
                      ]}
                    />
                  </ResourceDetailRowEdit>

                  { values.country === 'ES' && (
                    <>
                      <ResourceDetailRowEdit label="Region (Comunidad)">
                        <Select
                          name="location.region"
                          label="Region (Comunidad)"
                          options={[
                            { label: 'ANDALUCIA (1)', value: 1 },
                            { label: 'ARAGÓN (2)', value: 2 },
                            { label: 'ASTURIAS (3)', value: 3 },
                            { label: 'C. VALENCIANA (17)', value: 17 },
                            { label: 'CANARIAS (5)', value: 5 },
                            { label: 'CANTABRIA (6)', value: 6 },
                            { label: 'CASTILLA-LA MANCHA (7)', value: 7 },
                            { label: 'CASTILLA Y LEÓN (8)', value: 8 },
                            { label: 'CATALUÑA (9)', value: 9 },
                            { label: 'EXTREMADURA (10)', value: 10 },
                            { label: 'GALICIA (11)', value: 11 },
                            { label: 'ILLES BALEARS (4)', value: 4 },
                            { label: 'LA RIOJA (16)', value: 16 },
                            { label: 'MADRID (12)', value: 12 },
                            { label: 'MURCIA (13)', value: 13 },
                            { label: 'NAVARRA (14)', value: 14 },
                            { label: 'PAIS VASCO (15)', value: 15 },
                          ]}
                          onChange={(option: any) => {
                            setFieldValue('location.region', option.value)
                            setFieldValue('location.province', '')
                            setFieldValue('location.municipality', '')
                            setFieldValue('location.area', '')
                            setFieldValue('location.plot', '')
                            loadLocationOptions('provinces', `provincias/${option.value}`)
                          }}
                        />
                      </ResourceDetailRowEdit>
                    </>
                  ) }

                  { values.location.region !== '' && (
                    <>
                      <ResourceDetailRowEdit label="Province (Provincia)">
                        <Select
                          name="location.province"
                          label="Province (Provincia)"
                          options={locationOptions.provinces}
                          isDisabled={locationOptions.provinces.length === 0}
                          onChange={(option: any) => {
                            setFieldValue('location.province', option.value)
                            setFieldValue('location.municipality', '')
                            setFieldValue('location.area', '')
                            setFieldValue('location.plot', '')
                            loadLocationOptions('municipalities', `municipios/${option.value}`)
                          }}
                        />
                      </ResourceDetailRowEdit>
                    </>
                  ) }

                  { values.location.province !== '' && (
                    <>
                      <ResourceDetailRowEdit label="Municipality (Municipio)">
                        <Select
                          name="location.municipality"
                          label="Municipality (Municipio)"
                          options={locationOptions.municipalities}
                          isDisabled={locationOptions.municipalities.length === 0}
                          onChange={(option: any) => {
                            setFieldValue('location.municipality', option.value)
                            setFieldValue('location.area', '')
                            setFieldValue('location.plot', '')
                            loadLocationOptions('areas', `poligonos/${values.location.province}/${option.value}/0/0`)
                          }}
                        />
                      </ResourceDetailRowEdit>
                    </>
                  ) }

                  { values.location.municipality !== '' && (
                    <>
                      <ResourceDetailRowEdit label="Area (Polígono)">
                        <Select
                          name="location.area"
                          label="Area (Polígono)"
                          options={locationOptions.areas}
                          isDisabled={locationOptions.areas.length === 0}
                          onChange={(option: any) => {
                            setFieldValue('location.area', option.value)
                            setFieldValue('location.plot', '')
                            loadLocationOptions('plots', `parcelas/${values.location.province}/${values.location.municipality}/0/0/${option.value}`)
                          }}
                        />
                      </ResourceDetailRowEdit>
                    </>
                  ) }

                  { values.location.area !== '' && (
                    <>
                      <ResourceDetailRowEdit label="Plot (Parcela)">
                        <Select
                          name="location.plot"
                          label="Plot (Parcela)"
                          options={locationOptions.plots}
                          isDisabled={locationOptions.plots.length === 0}
                        />
                      </ResourceDetailRowEdit>
                    </>
                  ) }
                </ResourceDetailGroup>

                <div className="d-flex justify-content-end">
                  <LoadingButton
                    busy={isSubmitting}
                    type="submit"
                    className="btn btn-success px-5 mb-5"
                  >
                    Create opportunity
                  </LoadingButton>
                </div>
              </Form>
            </>
          ) }
        </Formik>
      </Container>
    </>
  )
}

import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { ArrowLeft } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import LoadingButton from '../components/LoadingButton'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRowEdit from '../components/ResourceDetailRowEdit'
import BelongsTo from '../components/fields/BelongsTo'
import Currency from '../components/fields/Currency'
import Select from '../components/fields/Select'
import api from '../utils/api'

export default function InvestmentCreate() {
  const abortController = new AbortController()

  const navigate = useNavigate()

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Container fluid>
        <Formik
          initialValues={{
            investor_id: '',
            offering_id: '',
            amount: ''
          }}
          onSubmit={async (values, actions) => {
            try {
              const response = await api.post('/investments', values, { signal: abortController.signal })

              if (response !== undefined) {
                toast.success('Investment created successfully.')
                navigate(`/investments/${response.data.data.id}`)
              }
            }
            catch (err) {
              if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                actions.setErrors(err.response.data.errors)
              }
            }
          }}
        >
          {({ values, isSubmitting }) => (
            <>
              <div className="header mt-2">
                <div className="header-body">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link
                          to="/investments"
                          className="text-muted"
                        >
                          Investments
                        </Link>
                      </li>

                      <li className="breadcrumb-item text-primary">
                        Create investment
                      </li>
                    </ol>

                    <Link
                      to="/investments"
                      className="btn btn-link text-secondary"
                    >
                      <ArrowLeft
                        size="14px"
                        className="me-2"
                      />
                      Back to list
                    </Link>
                  </div>
                </div>
              </div>

              <Form>
                <ResourceDetailGroup 
                  title="Investment details"
                >
                  <ResourceDetailRowEdit label="Investor">
                    <BelongsTo
                      name="investor_id"
                      labelName="investor_name"
                      label="Investor"
                      endpoint="/investors"
                    />
                  </ResourceDetailRowEdit>

                  <ResourceDetailRowEdit label="Offering">
                    <BelongsTo
                      name="offering_id"
                      labelName="offering_name"
                      label="Offering"
                      endpoint="/offerings"
                    />
                  </ResourceDetailRowEdit>

                  <ResourceDetailRowEdit label="Amount">
                    <Currency
                      name="amount"
                      label="Amount"
                    />
                  </ResourceDetailRowEdit>

                  <ResourceDetailRowEdit label="Payment method">
                    <Select
                      name="method"
                      label="Payment method"
                      isSearchable={false}
                      options={[
                        { label: 'Bank transfer', value: 'bank_transfer' }
                      ]}
                    />
                  </ResourceDetailRowEdit>
                </ResourceDetailGroup>

                <div className="d-flex justify-content-end">
                  <LoadingButton
                    busy={isSubmitting}
                    type="submit"
                    className="btn btn-success px-5 mb-5"
                  >
                    Create investment
                  </LoadingButton>
                </div>
              </Form>
            </>
          ) }
        </Formik>
      </Container>
    </>
  )
}

import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Logo() {
  return (
    <Link to="/">
      <Navbar.Brand>
        <img
          className="navbar-brand-img mb-3"
          src="/images/logo.png"
          srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
          alt="Bageera Admin"
          style={{ height: '30px' }}
        />
      </Navbar.Brand>
    </Link>
  )
}

import { useEffect, useState } from 'react'
import { Dropdown, Modal, Spinner } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { RefreshCw } from 'react-feather'

import api from '../../utils/api'

interface OpportunityRefreshProps extends DropdownItemProps {
  opportunityId: number;
  onSuccess: () => void;
  disabled?: boolean;
}

enum Status {
  ShowButton,
  Loading
}

export default function OpportunityRefresh({ opportunityId, onSuccess, disabled = false, ...props }: OpportunityRefreshProps) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => {
          setStatus(Status.Loading)

          api.put(`/opportunities/${opportunityId}`, {}, { signal: abortController.signal })
            .then(response => {
              if (response !== undefined) {
                setStatus(Status.ShowButton)
                onSuccess()
              }
            })
            .catch(err => {
              setStatus(Status.ShowButton)
            })
        }}
        disabled={disabled || status === Status.Loading}
        {...props}
      >
        <RefreshCw
          size="14px"
          className="me-3"
        /> Refresh data
      </Dropdown.Item>

      <Modal
        show={status === Status.Loading}
        centered
        size="sm"
      >
        <Modal.Body className="d-flex align-items-center justify-content-center">
          <Spinner
            animation="border"
            size="sm"
            className="me-3"
          /> Updating opportunity data...
        </Modal.Body>
      </Modal>
    </>
  )
}

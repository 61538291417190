import ResourceDetailExternalAction from '../components/ResourceDetailExternalAction'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRow from '../components/ResourceDetailRow'
import OpportunityRefresh from '../components/actions/OpportunityRefresh'
import ResourceDelete from '../components/actions/ResourceDelete'
import { countryName, currencyFormat } from '../helpers'

import ResourceShow from './generic/ResourceShow'

enum Views {
  Info = 'info',
}

export default function OpportunityShow() {
  return (
    <ResourceShow
      name={{ singular: 'opportunity', plural: 'opportunities' }}
      views={[
        {
          label: 'Information',
          value: Views.Info,
        }
      ]}
      options={(opportunity, helpers) => (
        <>
          <OpportunityRefresh
            opportunityId={opportunity.id}
            onSuccess={() => { helpers.loadResource() }}
          />

          <ResourceDelete
            resourceName="opportunity"
            endpoint={`/opportunities/${opportunity.id}`}
            redirectTo="/opportunities"
            disabled={true}
          />
        </>
      )}
    >
      { (opportunity, view, helpers) => (
        <>
          { view === Views.Info && (
            <>
              <ResourceDetailGroup 
                title="Identification details"
              >
                <ResourceDetailRow label="Name">
                  { opportunity.name }
                </ResourceDetailRow>

                <ResourceDetailRow label="Country">
                  { countryName(opportunity.country) }
                </ResourceDetailRow>

                { opportunity.country === 'ES' && (
                  <>
                    <ResourceDetailRow label="Location">
                      <ResourceDetailExternalAction
                        title="Show in SIGPAC"
                        href={`https://sigpac.mapa.es/fega/visor/?provincia=${opportunity.location.province}&municipio=${opportunity.location.municipality}&agregado=0&zona=0&poligono=${opportunity.location.area}&parcela=${opportunity.location.plot}`}
                      />

                      Provincia { opportunity.location.province }, 

                      Municipio { opportunity.location.municipality }, 

                      Polígono { opportunity.location.area },

                      Parcela { opportunity.location.plot }
                    </ResourceDetailRow>
                  </>
                ) }
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="General data"
              >
                <ResourceDetailRow label="Province">
                  { opportunity.meta.province }
                </ResourceDetailRow>

                <ResourceDetailRow label="Municipality">
                  { opportunity.meta.municipality }
                </ResourceDetailRow>

                <ResourceDetailRow label="Area">
                  { (opportunity.meta.area / 10000).toFixed(2) } ha
                </ResourceDetailRow>

                <ResourceDetailRow label="Type of soil">
                  { opportunity.meta.soil_type }
                </ResourceDetailRow>

                <ResourceDetailRow label="CAP BPS payment (estimated)">
                  <ResourceDetailExternalAction
                    title="View average BPS values"
                    href="https://www.fega.gob.es/sites/default/files/VMR_2022_en_el_regimen_de_pago_basico_v1.0.pdf"
                  />

                  { currencyFormat(opportunity.meta.cap_payment, 'EUR', true) } per year (2022)
                </ResourceDetailRow>

                <ResourceDetailRow label="Cadastral reference">
                  <ResourceDetailExternalAction
                    title="Show in Sede Electrónica del Catastro"
                    href={`https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCConCiud.aspx?del=${opportunity.location.province}&mun=${opportunity.location.municipality}&UrbRus=R&RefC=${opportunity.meta.cadastral_ref}`}
                  />

                  { opportunity.meta.cadastral_ref }
                </ResourceDetailRow>

                <ResourceDetailRow label="Coordinates (estimated)">
                  <ResourceDetailExternalAction
                    title="Show in Google Maps"
                    href={`https://www.google.com/maps?q=${opportunity.meta.coordinates[1]}+${opportunity.meta.coordinates[0]}`}
                  />
                  
                  { opportunity.meta.coordinates[0] } { opportunity.meta.coordinates[1] }
                </ResourceDetailRow>

                <ResourceDetailRow label="Elevation (estimated)">
                  { opportunity.meta.elevation ?? '–' } m
                </ResourceDetailRow>

                <ResourceDetailRow label="Polygon coordinates (estimated)">
                  <ResourceDetailExternalAction
                    title="Show in Keene Polyline Tool"
                    href={
                      'https://www.keene.edu/campus/maps/tool/?coordinates=' 
                      + opportunity.meta.polygon_coordinates.map((coord: any) => `${coord[0]}%2C%20${coord[1]}`).join('%0A')
                    }
                  />

                  { opportunity.meta.polygon_coordinates.map((coord: any) => (
                    <>{ coord[0] } { coord[1] }<br/></>
                  )) }
                </ResourceDetailRow>

                <ResourceDetailRow label="Land uses">
                  { opportunity.meta.uses.map((use: any) => (
                    <>
                      { use.type }: { (use.area / 10000).toFixed(2) } ha ({ (use.area / opportunity.meta.area * 100).toFixed(2) }%)<br/>
                    </>
                  )) }
                </ResourceDetailRow>

                <ResourceDetailRow label="Last update">
                  { opportunity.meta.last_update.substring(0, 10) }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Subplots"
              >
                { opportunity.meta.subplots.map((subplot: any) => (
                  <ResourceDetailRow label={`Subplot ${subplot.id}`}>
                    Area: { (subplot.area / 10000).toFixed(2) } ha ({ (subplot.area / opportunity.meta.area * 100).toFixed(2) }%)<br/>

                    Average slope: { subplot.slope.toFixed(2) }%<br/>

                    Land use: { subplot.use }<br/>

                    { subplot.cap_region && (
                      <>Region for <abbr title="Common Agricultural Policy Basic Payment Scheme">CAP BPS</abbr>: { subplot.cap_region }</>
                    ) }
                  </ResourceDetailRow>
                )) }
              </ResourceDetailGroup>
            </>
          ) }
        </>
      ) }
    </ResourceShow>
  )
}

import { Badge, Nav } from 'react-bootstrap'

export interface HeaderTabsProps {
  tabs: {
    label: string;
    value: string|null;
    active?: boolean;
    count?: number;
  }[];
  value: string|null;
  onChange: (value: string|null) => void;
}

export default function HeaderTabs({ tabs, value, onChange }: HeaderTabsProps) {
  return (
    <div className="header-tabs nav nav-tabs nav-overflow">
      { tabs.map(tab => (
        <Nav.Item key={tab.value || 'default'}>
          <Nav.Link
            role="button"
            className="text-nowrap pb-3"
            active={ tab.active || tab.value === value }
            onClick={() => onChange(tab.value)}
          >
            { tab.label }
            
            { tab.count && (
              <Badge
                bg="secondary-soft"
                className="rounded-pill ms-2"
              >
                { tab.count }
              </Badge>
            ) }
          </Nav.Link>
        </Nav.Item>
      )) }
    </div>
  )
}

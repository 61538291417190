import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import api from './utils/api'
import { useAppSelector, useAppDispatch } from './utils/hooks'
import { fetchMeta, selectUser } from './utils/store/meta'
import { selectStatus, setStatusCode } from './utils/store/status'
import Dashboard from './views/Dashboard'
import InvestmentCreate from './views/InvestmentCreate'
import InvestmentIndex from './views/InvestmentIndex'
import InvestmentShow from './views/InvestmentShow'
import InvestorIndex from './views/InvestorIndex'
import InvestorShow from './views/InvestorShow'
import OfferingEdit from './views/OfferingEdit'
import OfferingIndex from './views/OfferingIndex'
import OfferingShow from './views/OfferingShow'
import OpportunityCreate from './views/OpportunityCreate'
import OpportunityIndex from './views/OpportunityIndex'
import OpportunityShow from './views/OpportunityShow'
import UserCreate from './views/UserCreate'
import UserEdit from './views/UserEdit'
import UserIndex from './views/UserIndex'
import UserShow from './views/UserShow'
import Loading from './views/aux/Loading'
import NotAdmin from './views/errors/NotAdmin'
import NotAuthorized from './views/errors/NotAuthorized'
import NotFound from './views/errors/NotFound'
import SiteLayout from './views/layouts/SiteLayout'

export default function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getAccessTokenSilently({
          audience: 'https://api.bageera.co'
        })
          .then(accessToken => {
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            dispatch(fetchMeta())
          })
      } else {
        loginWithRedirect()
      }
    }
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    dispatch(setStatusCode(200))
  }, [location.key])

  const user = useAppSelector(selectUser)
  const status = useAppSelector(selectStatus)

  if (status.code === 401) {
    return (
      <NotAdmin />
    )
  }

  if (isLoading || user === null || status.code === -1) {
    return (
      <Loading absolute={true} />
    )
  }

  if (status.code === 403) {
    return (
      <NotAuthorized />
    )
  }

  if (status.code === 404) {
    return (
      <NotFound />
    )
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SiteLayout />
        }
      >
        <Route
          path=""
          element={
            <Dashboard />
          }
        />

        <Route
          path="investments"
          element={
            <InvestmentIndex />
          }
        />

        <Route
          path="investments/create"
          element={
            <InvestmentCreate />
          }
        />

        <Route
          path="investments/:id"
          element={
            <InvestmentShow />
          }
        />

        <Route
          path="investors"
          element={
            <InvestorIndex />
          }
        />

        <Route
          path="investors/:id"
          element={
            <InvestorShow />
          }
        />

        <Route
          path="offerings"
          element={
            <OfferingIndex />
          }
        />

        <Route
          path="offerings/:id"
          element={
            <OfferingShow />
          }
        />

        <Route
          path="offerings/:id/edit"
          element={
            <OfferingEdit />
          }
        />

        <Route
          path="opportunities"
          element={
            <OpportunityIndex />
          }
        />

        <Route
          path="opportunities/create"
          element={
            <OpportunityCreate />
          }
        />

        <Route
          path="opportunities/:id"
          element={
            <OpportunityShow />
          }
        />

        <Route
          path="users"
          element={
            <UserIndex />
          }
        />

        <Route
          path="users/create"
          element={
            <UserCreate />
          }
        />

        <Route
          path="users/:id"
          element={
            <UserShow />
          }
        />

        <Route
          path="users/:id/edit"
          element={
            <UserEdit />
          }
        />

        <Route
          path="*"
          element={<NotFound />}
        />
      </Route>
    </Routes>
  )
}

import axios from 'axios'
import { Form, Formik } from 'formik'
import { ReactNode, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { ArrowLeft } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import LoadingButton from '../../components/LoadingButton'
import api from '../../utils/api'

export interface ResourceCreateProps {
  name: {
    singular: string;
    plural: string;
  };
  initialValues: {[key: string]: any};
  children: ReactNode;
}

export default function ResourceCreate({ name, initialValues, children }: ResourceCreateProps) {
  const abortController = new AbortController()

  const navigate = useNavigate()

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Container fluid>
        <div className="header mt-2">
          <div className="header-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    to={`/${name.plural}`}
                    className="text-muted"
                  >
                    { name.plural.charAt(0).toUpperCase() + name.plural.substring(1) }
                  </Link>
                </li>

                <li className="breadcrumb-item text-primary">
                  Create {name.singular}
                </li>
              </ol>

              <div className="d-flex">
                <Link
                  to={`/${name.plural}`}
                  className="btn btn-link text-secondary me-2"
                >
                  <ArrowLeft
                    size="14px"
                    className="me-2"
                  />

                  Back to list
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            try {
              const response = await api.post(`/${name.plural}`, values, { signal: abortController.signal })

              if (response !== undefined) {
                toast.success(name.singular.charAt(0).toUpperCase() + name.singular.substring(1) + ' created successfully.')
                navigate(`/${name.plural}/${response.data.data.id}`)
              }
            }
            catch (err) {
              if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                actions.setErrors(err.response.data.errors)
              }
            }
          }}
        >
          { ({ isSubmitting }) => (
            <Form>
              { children }

              <div className="d-flex justify-content-end">
                <LoadingButton
                  busy={isSubmitting}
                  type="submit"
                  className="btn btn-success px-5 mb-5"
                >
                  Create {name.singular}
                </LoadingButton>
              </div>
            </Form>
          ) }
        </Formik>
      </Container>
    </>
  )
}

import classNames from 'classnames'
import { useField } from 'formik'
import { HTMLProps } from 'react'
import DropzoneAlias, { DropzoneProps } from 'react-dropzone'
import { Check, Upload } from 'react-feather'

import { humanFileSize } from '../../helpers'
import ErrorFeedback from '../errors/ErrorFeedback'

interface DropzoneFieldProps {
  name: string,
  placeholder?: string,
  footer?: string,
  divProps?: HTMLProps<HTMLDivElement>,
  messageProps?: HTMLProps<HTMLDivElement>,
  multiple?: boolean,
}

export default function Dropzone({name, placeholder, footer, divProps, messageProps, multiple = false, ...dropzoneProps}: DropzoneFieldProps & DropzoneProps) {
  const [field, meta, helpers] = useField({ type: 'file', name: name })
  
  return (
    <div {...divProps}>
      <DropzoneAlias
        onDrop={(files) => helpers.setValue(files.length > 0 ? (multiple ? files : files[0]) : null)}
        multiple={multiple}
        {...dropzoneProps}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={classNames(
              'dropzone',
              meta.error && 'is-invalid'
            )}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            { field.value
              ? (
                <div
                  className="dz-message"
                  {...messageProps}
                >
                  <p className="text-primary mb-2">
                    { Array.isArray(field.value) && (
                      <span>Array</span>
                    ) } 

                    { field.value instanceof File && (
                      <>
                        <Check
                          size="16px"
                          className="me-2"
                        /> 
                        
                        { placeholder ? placeholder : 'Selected file' }: { field.value.name } ({ humanFileSize(field.value.size) })
                      </>
                    ) }
                  </p>

                  <p className="text-muted mb-0 small">
                    Click or drop { multiple ? 'files' : 'a file' } to replace current document
                  </p>
                </div>
              ) 
              : (
                <div
                  className="dz-message"
                  {...messageProps}
                >
                  <p className={footer ? 'mb-2' : 'mb-0'}>
                    <Upload
                      size="14px"
                      className="me-2"
                    /> 

                    {' '}
                    
                    Click or drop { multiple ? 'files' : 'a file' } to upload{ placeholder && `: ${placeholder}` }
                  </p>

                  { footer && (
                    <p className="mb-0 small">
                      { footer }
                    </p>
                  ) }
                </div>
              )}
          </div>
        )}
      </DropzoneAlias>

      <ErrorFeedback error={meta.error} />
    </div>
  )
}

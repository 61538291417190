import { useEffect } from 'react'

import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRowEdit from '../components/ResourceDetailRowEdit'
import Country from '../components/fields/Country'
import Select from '../components/fields/Select'
import Text from '../components/fields/Text'

import ResourceCreate from './generic/ResourceCreate'

export default function UserCreate() {
  const abortController = new AbortController()

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <ResourceCreate
      name={{ singular: 'user', plural: 'users' }}
      initialValues={{
        first_name: '',
        last_name: '',
        country: '',
        email: '',
        phone: '',
        investment_range: '',
        heard_from: '',
        passed_welcome: false,
      }}
    >
      <ResourceDetailGroup 
        title="Identification details"
      >
        <ResourceDetailRowEdit label="First name">
          <Text
            name="first_name"
            label="First name"
          />
        </ResourceDetailRowEdit>

        <ResourceDetailRowEdit label="Last name">
          <Text
            name="last_name"
            label="Last name"
          />
        </ResourceDetailRowEdit>

        <ResourceDetailRowEdit label="Country of residence">
          <Country
            name="country"
            label="Country of residence"
          />
        </ResourceDetailRowEdit>
      </ResourceDetailGroup>

      <ResourceDetailGroup 
        title="Contact details"
      >
        <ResourceDetailRowEdit label="Email address">
          <Text
            name="email"
            label="Email address"
          />
        </ResourceDetailRowEdit>

        <ResourceDetailRowEdit label="Phone number (with prefix)">
          <Text
            name="phone"
            label="Phone number"
          />
        </ResourceDetailRowEdit>
      </ResourceDetailGroup>

      <ResourceDetailGroup 
        title="Others"
      >
        <ResourceDetailRowEdit label="Skip welcome on first login">
          <Select
            name="passed_welcome"
            label="Skip welcome on first login"
            isSearchable={false}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
          />
        </ResourceDetailRowEdit>
      </ResourceDetailGroup>
    </ResourceCreate>
  )
}

import classNames from 'classnames'
import { Field, FieldAttributes, useField } from 'formik'

import ErrorFeedback from '../errors/ErrorFeedback'

export interface TextareaProps {
  name: string,
  label: string
}

export default function Textarea({ name, label, ...props }: TextareaProps & FieldAttributes<any>) {
  const [field, meta, helpers] = useField({ type: 'text', name: name })

  return (
    <>
      <Field
        className={classNames(
          'form-control',
          meta.error && 'is-invalid'
        )}
        as="textarea"
        id={name}
        name={name}
        placeholder={label}
        {...props}
      />

      <ErrorFeedback error={meta.error} />
    </>
  )
}

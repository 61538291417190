import axios from 'axios'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem'
import { CheckCircle } from 'react-feather'
import { toast } from 'react-toastify'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'
import DateTime from '../fields/DateTime'

interface InvestmentMarkAsPaidProps extends DropdownItemProps {
  investmentId: number;
  onSuccess: (updatedInvestment: { [key: string]: any }) => void;
  disabled?: boolean;
}

enum Status {
  ShowButton,
  ShowModal
}

export default function InvestmentMarkAsPaid({ investmentId, onSuccess, disabled = false, ...props }: InvestmentMarkAsPaidProps) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <Dropdown.Item 
        className="d-flex align-items-center"
        onClick={() => setStatus(Status.ShowModal)}
        disabled={disabled}
        {...props}
      >
        <CheckCircle
          size="14px"
          className="me-3"
        /> Mark as paid
      </Dropdown.Item>

      <Modal
        show={status === Status.ShowModal}
        onHide={() => setStatus(Status.ShowButton)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Mark as paid
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={{
              paid_at: new Date().toISOString().replace('T', ' ').substring(0, 16)
            }}
            onSubmit={async (values, actions) => {
              try {
                const response = await api.post(`/investments/${investmentId}/mark_paid`, values, { signal: abortController.signal })

                if (response !== undefined) {
                  toast.success('Investment mark as paid.')
                  setStatus(Status.ShowButton)
                  onSuccess(response.data.data.investment)
                }
              }
              catch (err) {
                if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                  actions.setErrors(err.response.data.errors)
                }
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <p className="mb-3 pb-1">
                  Please choose the date in which the payment for this investment was received:
                </p>

                <div className="d-flex flex-column mb-3">
                  <DateTime
                    label="Payment date"
                    name="paid_at"
                    className="text-center"
                    options={{
                      monthSelectorType: 'static'
                    }}
                  />
                </div>

                <p className="pt-1">
                  The introduced time should be in GMT.
                </p>

                <p className="text-right mb-0">
                  <Button
                    variant="link"
                    className="text-secondary me-2"
                    onClick={() => setStatus(Status.ShowButton)}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    type="submit"
                    busy={isSubmitting}
                    variant="info"
                  >
                    Mark as paid
                  </LoadingButton>
                </p>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

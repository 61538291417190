import { ArrowUpRight } from 'react-feather'
import { Link } from 'react-router-dom'

import ResourceDetailExternalAction from '../components/ResourceDetailExternalAction'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailRow from '../components/ResourceDetailRow'
import InvestmentGetSignatureLink from '../components/actions/InvestmentGetSignatureLink'
import InvestmentMarkAsPaid from '../components/actions/InvestmentMarkAsPaid'
import InvestmentRegenerateAgreement from '../components/actions/InvestmentRegenerateAgreement'
import InvestmentUpdateSignatureStatus from '../components/actions/InvestmentUpdateSignatureStatus'
import ResourceDelete from '../components/actions/ResourceDelete'
import { currencyFormat } from '../helpers'

import ResourceShow from './generic/ResourceShow'

enum Views {
  Info = 'info',
  GeneratedAgreement = 'generated_agreement',
  SignedAgreement = 'signed_agreement'
}

export default function InvestmentShow() {
  return (
    <ResourceShow
      name={{ singular: 'investment', plural: 'investments' }}
      getResourceName={(investment) => `Investment #${investment.id}`}
      views={[
        {
          label: 'Information',
          value: Views.Info,
        },
        {
          label: 'Generated agreement',
          value: Views.GeneratedAgreement,
        },
      ]}
      options={(investment, helpers) => (
        <>
          <InvestmentRegenerateAgreement
            investmentId={investment.id}
            onSuccess={(updatedInvestment: { [key: string]: any }) => {
              helpers.setResource(updatedInvestment)
              helpers.setView(Views.GeneratedAgreement)
            }}
            disabled={investment.status !== 'created' && investment.status !== 'prepared'}
          />

          <InvestmentGetSignatureLink
            investmentId={investment.id}
            onSuccess={(updatedInvestment: { [key: string]: any }) => {
              helpers.setResource(updatedInvestment)
            }}
            disabled={investment.status !== 'prepared' && investment.status !== 'reviewed'}
          />

          <InvestmentUpdateSignatureStatus
            investmentId={investment.id}
            onSuccess={(updatedInvestment: { [key: string]: any }) => {
              helpers.setResource(updatedInvestment)
            }}
            disabled={investment.status !== 'reviewed'}
          />

          <InvestmentMarkAsPaid
            investmentId={investment.id}
            onSuccess={(updatedInvestment: { [key: string]: any }) => {
              helpers.setResource(updatedInvestment)
            }}
            disabled={investment.status !== 'signed'}
          />

          <ResourceDelete
            resourceName="investment"
            endpoint={`/investments/${investment.id}`}
            redirectTo="/investments"
            disabled={investment.status === 'signed' || investment.status === 'paid'}
          />
        </>
      )}
    >
      { (investment, view) => (
        <>
          { view === Views.Info && (
            <>
              <ResourceDetailGroup 
                title="Investment details"
              >
                <ResourceDetailRow label="Investor">
                  <Link to={`/investors/${investment.investor_id}`}>
                    { investment.investor_name }

                    <ArrowUpRight
                      size="16px"
                      className="ms-2"
                    />
                  </Link>
                </ResourceDetailRow>

                <ResourceDetailRow label="Offering">
                  <Link to={`/offerings/${investment.offering_id}`}>
                    { investment.offering_name }

                    <ArrowUpRight
                      size="16px"
                      className="ms-2"
                    />
                  </Link>
                </ResourceDetailRow>

                <ResourceDetailRow label="Amount">
                  { currencyFormat(investment.amount, investment.currency) }
                </ResourceDetailRow>

                <ResourceDetailRow label="Status">
                  { investment.status === 'created' && 'Created (generating documents)' }

                  { investment.status === 'prepared' && 'Prepared (review pending)' }

                  { investment.status === 'reviewed' && 'Reviewed (signature pending)' }

                  { investment.status === 'signed' && 'Signed (payment pending)' }

                  { investment.status === 'paid' && 'Paid' }

                  { investment.status === 'declined' && 'Declined' }
                </ResourceDetailRow>

                <ResourceDetailRow label="DocuSign envelope ID">
                  { investment.docusign_envelope_id && (
                    <ResourceDetailExternalAction
                      title="View on DocuSign"
                      href={investment.docusign_envelope_url}
                    />
                  ) }

                  { investment.docusign_envelope_id || '–' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Creation date">
                  { investment.created_at || '–' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Signature date">
                  { investment.signed_at || '–' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Payment date">
                  { investment.paid_at || '–' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Declinature date">
                  { investment.declined_at || '–' }
                </ResourceDetailRow>
              </ResourceDetailGroup>
            </>
          ) }

          { view === Views.GeneratedAgreement && (
            <>
              <iframe
                src={investment.agreement_url}
                width="100%"
                height="600px"
                title="Offering agreement"
              />
            </>
          ) }
        </>
      ) }
    </ResourceShow>
  )
}

import { useAuth0 } from '@auth0/auth0-react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Briefcase, DollarSign, Eye, Home, Image, LogOut, User, Users } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'

import { useAppSelector } from '../../utils/hooks'
import { selectUser } from '../../utils/store/meta'

import Logo from './Logo'

export default function Sidenav() {
  const { logout } = useAuth0()
  const location = useLocation()

  const user = useAppSelector(selectUser)

  return (
    <>
      <Navbar
        expand="md"
        className="navbar-vertical fixed-start"
        collapseOnSelect={true}
      >
        <Container fluid>
          <Navbar.Toggle />

          <Logo />

          <Navbar.Collapse>
            <Nav>
              <Nav.Item>
                <Link to="/">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/'}
                  >
                    <Home
                      size="15px"
                      className="me-3"
                    />
                    Dashboard
                  </Nav.Link>
                </Link>
              </Nav.Item>

              <hr className="navbar-divider" />

              <h6 className="navbar-heading px-4 mb-2">Sourcing</h6>

              <Nav.Item>
                <Link to="/offerings">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/offerings' || location.pathname.startsWith('/offerings/')}
                  >
                    <Image
                      size="15px"
                      className="me-3"
                    />
                    Offerings
                  </Nav.Link>
                </Link>
              </Nav.Item>


              <hr className="navbar-divider" />

              <h6 className="navbar-heading px-4 mb-2">Operations</h6>

              <Nav.Item>
                <Link to="/investments">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/investments' || location.pathname.startsWith('/investments/')}
                  >
                    <DollarSign
                      size="15px"
                      className="me-3"
                    />
                    Investments
                  </Nav.Link>
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link to="/investors">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/investors' || location.pathname.startsWith('/investors/')}
                  >
                    <Briefcase
                      size="15px"
                      className="me-3"
                    />
                    Investors
                  </Nav.Link>
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link to="/users">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/users' || location.pathname.startsWith('/users/')}
                  >
                    <Users
                      size="15px"
                      className="me-3"
                    />
                    Users
                  </Nav.Link>
                </Link>
              </Nav.Item>
            </Nav>

            <div className="navbar-user mt-auto fs-5 justify-content-between">
              <div className="overflow-ellipsis">
                <User
                  size="14px"
                  className="me-2"
                />
              
                <span title={user?.name}>
                  { user?.name }
                </span>
              </div>

              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="btn btn-sm btn-outline-primary btn-rounded-circle ms-3 flex-shrink-0"
                title="Log out"
              >
                <LogOut
                  size="13px"
                  className="mb-1"
                />
              </button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

import { Dropdown } from 'react-bootstrap'
import { ArrowUpRight, Edit2 } from 'react-feather'
import { Link } from 'react-router-dom'

import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailHeading from '../components/ResourceDetailHeading'
import ResourceDetailRow from '../components/ResourceDetailRow'
import ResourceTable from '../components/ResourceTable'
import ResourceDelete from '../components/actions/ResourceDelete'
import { countryName, currencyFormat } from '../helpers'

import ResourceShow from './generic/ResourceShow'

enum Views {
  Info = 'info',
  Summary = 'summary',
  Investments = 'investments',
}

export default function OfferingShow() {
  return (
    <ResourceShow
      name={{ singular: 'offering', plural: 'offerings' }}
      views={[
        {
          label: 'Information',
          value: Views.Info,
        },
        {
          label: 'Investments',
          value: Views.Investments,
        },
      ]}
      options={(offering, helpers) => (
        <>
          <Link to={`/offerings/${offering.id}/edit`}>
            <Dropdown.Item
              as="span" 
              className="d-flex align-items-center"
            >
              <Edit2
                size="14px"
                className="me-3"
              /> Edit offering
            </Dropdown.Item>
          </Link>

          <ResourceDelete
            resourceName="offering"
            endpoint={`/offerings/${offering.id}`}
            redirectTo="/offerings"
            disabled={true}
          />
        </>
      )}
    >
      { (offering, view, helpers) => (
        <>
          { view === Views.Info && (
            <>
              <ResourceDetailGroup 
                title="Offering details"
              >
                <ResourceDetailRow label="Name">
                  { offering.name }
                </ResourceDetailRow>

                <ResourceDetailRow label="Type">
                  { offering.meta.type === 'individual' && 'Individual property' }

                  { offering.meta.type === 'portfolio' && 'Portfolio' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Status">
                  { offering.status[0].toUpperCase() + offering.status.substring(1) }
                </ResourceDetailRow>
                
                <ResourceDetailRow label="Total Investment Offering">
                  { currencyFormat(offering.amount, offering.currency) }
                </ResourceDetailRow>

                <ResourceDetailRow label="Description">
                  { offering.description }
                </ResourceDetailRow>

                <ResourceDetailRow label="Crop">
                  { offering.meta.crop }
                </ResourceDetailRow>

                <ResourceDetailRow label="Location">
                  { offering.location }
                </ResourceDetailRow>
                
                <ResourceDetailRow label="Country">
                  { countryName(offering.country) }
                </ResourceDetailRow>

                <ResourceDetailRow label="Area">
                  { offering.meta.area }
                </ResourceDetailRow>

                <ResourceDetailRow label="Project Operator">
                  { offering.meta.project_operator }
                </ResourceDetailRow>

                <ResourceDetailRow label="Expected Duration">
                  { offering.meta.expected_duration }
                </ResourceDetailRow>

                <ResourceDetailRow label="Farmland">
                  { offering.meta.farmland }
                </ResourceDetailRow>

                <ResourceDetailRow label="Productive Area">
                  { offering.meta.productive_area }
                </ResourceDetailRow>

                <ResourceDetailRow label="Gross Cash Yield">
                  { offering.meta.yield }
                </ResourceDetailRow>

                <ResourceDetailRow label="Net Anual IRR">
                  { offering.meta.irr }
                </ResourceDetailRow>

                <ResourceDetailRow label="Sustainability points">
                  { (offering.meta.sustainability ?? []).map((point: string, idx: number) => (
                    <span key={idx}>– {point}<br/></span>
                  )) }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Attachments"
              >
                <ResourceDetailRow label="Investment summary">
                  { offering.summary_url !== null ? (
                    <a href={offering.summary_url}>
                      Download
                    </a>
                  ) : '–' }
                </ResourceDetailRow>
              </ResourceDetailGroup>
            </>
          ) }

          { view === Views.Investments && (
            <>
              <ResourceDetailHeading>
                Related investments
              </ResourceDetailHeading>

              <ResourceTable
                endpoint={`/offerings/${offering.id}/investments`}
                columns={[
                  {
                    key: 'investor',
                    header: 'Investor',
                    headerClass: 'col-3',
                    rowClass: 'overflow-ellipsis',
                    render: (row: { [key: string]: any }) => (
                      <>
                        { row.investor_name }
              
                        <Link
                          to={`/investors/${row.investor_id}`}
                        >
                          <ArrowUpRight
                            size="16px"
                            className="ms-2"
                          />
                        </Link>
                      </>
                    )
                  },
                  {
                    key: 'amount',
                    header: 'Amount',
                    render: (row: { [key: string]: any }) => currencyFormat(row.amount, row.currency)
                  },
                  {
                    key: 'status',
                    header: 'Status',
                    accessor: 'status'
                  },
                  {
                    key: 'updated_at',
                    header: 'Updated',
                    accessor: 'updated_at'
                  }
                ]}
                
                actions={[
                  {
                    key: 'view',
                    render: (row: { [key: string]: any }) => (
                      <Link
                        to={`/investments/${row.id}`}
                        className="btn btn-sm btn-primary"
                      >
                        View
                      </Link>
                    )
                  }
                ]}
              />
            </>
          ) }
        </>
      ) }
    </ResourceShow>
  )
}

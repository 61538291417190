import { useEffect, useState } from 'react'
import { Props as SelectProps } from 'react-select'

import api from '../../utils/api'

import Select from './Select'

export default function Country({ name, label, eligible = false, ...props }: { name: string, label: string, eligible?: boolean } & SelectProps) {
  const [loading, setLoading] = useState<boolean>(true)
  const [options, setOptions] = useState<{ [key: string]: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()

    api.get(`/services/country/list${ eligible ? '?eligible=true' : '' }`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setLoading(false)
          setOptions(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])

  return (
    <Select
      name={name}
      label={label}
      options={options}
      isSearchable={true}
      isLoading={loading}
      getOptionLabel={(option: any) => option.name}
      getOptionValue={(option: any) => option.code}
      {...props}
    />
  )
}

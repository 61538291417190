import { Outlet } from 'react-router'

import Sidenav from '../../components/sidenav/Sidenav'

export default function SiteLayout() {
  return (
    <>
      <Sidenav />

      <div className="main-content d-flex flex-column flex-auto">
        <Outlet />
      </div>
    </>
  )
}

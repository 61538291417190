import { ReactNode } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'

export default function ResourceDetailRow({ label, children }: { label: string, children: ReactNode }) {
  return (
    <ListGroup.Item
      className="px-4"
    >
      <Row>
        <Col
          xs={12}
          md={3}
          className="text-muted"
        >
          { label }
        </Col>

        <Col
          xs={12}
          md={9}
        >
          { children }
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

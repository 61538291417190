import { useMemo, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import HeaderTabs from '../components/HeaderTabs'
import ResourceTable from '../components/ResourceTable'
import { countryName, currencyFormat } from '../helpers'

export default function OpportunityIndex() {
  const [status, setStatus] = useState<string|null>(null)

  const columns = useMemo(() => [
    {
      key: 'name',
      header: 'Name',
      accessor: 'name'
    },
  ], [])

  const actions = useMemo(() => [
    {
      key: 'view',
      render: (row: { [key: string]: any }) => (
        <Link
          to={`/opportunities/${row.id}`}
          className="btn btn-sm btn-primary"
        >
          View
        </Link>
      )
    }
  ], [])

  return (
    <>
      <div className="header mt-2">
        <Container fluid>
          <div className="header-body mb-3">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h1 className="header-title">
                Opportunities
              </h1>

              <Link
                to="/opportunities/create"
                className="btn btn-success d-flex align-items-center"
              >
                Create opportunity
              </Link>
            </div>

            <HeaderTabs
              tabs={[
                {
                  label: 'All opportunities',
                  value: null,
                },
              ]}
              value={status}
              onChange={value => setStatus(value)}
            />
          </div>
        </Container>
      </div>

      <Container
        fluid
        className="flex-auto d-flex flex-column"
      >
        <ResourceTable
          endpoint="/opportunities"
          columns={columns}
          actions={actions}
          searchable={true}
        />
      </Container>
    </>
  )
}

import { ReactNode, useEffect, useState } from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import { ArrowLeft, Tool } from 'react-feather'
import { Link, useParams } from 'react-router-dom'

import HeaderTabs from '../../components/HeaderTabs'
import api from '../../utils/api'
import Loading from '../aux/Loading'

interface ResourceType {
  [key: string]: any;
}

export interface ResourceEditProps {
  name: {
    singular: string;
    plural: string;
  };
  getResourceName?: (resource: ResourceType) => string;
  children: (resource: ResourceType, view: string|null, helpers: {[key: string]: any}) => ReactNode;
  options: (resource: ResourceType, helpers: {[key: string]: any}) => ReactNode;
  views?: {
    label: string;
    value: string;
  }[];
}

export default function ResourceShow({ name, getResourceName = resource => resource.name, children, options, views }: ResourceEditProps) {
  const abortController = new AbortController()

  const { id } = useParams()

  const [resource, setResource] = useState<{[key: string]: any}|null>(null)
  const [view, setView] = useState<string|null>(views !== undefined ? views[0].value : null)

  const loadResource = () => {
    setResource(null)

    api.get(`/${name.plural}/${id}`, { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setResource(response.data.data)
        }
      })
  }

  useEffect(() => {
    loadResource()

    return () => abortController.abort()
  }, [id])

  if (resource === null) {
    return <Loading />
  }

  const helpers = { loadResource, setResource, setView }

  return (
    <>
      <Container fluid>
        <div className="header mt-2">
          <div className="header-body">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    to={`/${name.plural}`}
                    className="text-muted"
                  >
                    { name.plural.charAt(0).toUpperCase() + name.plural.substr(1) }
                  </Link>
                </li>

                <li className="breadcrumb-item text-primary">
                  { getResourceName(resource) }
                </li>
              </ol>

              <div className="d-flex">
                <Link
                  to={`/${name.plural}`}
                  className="btn btn-link text-secondary me-2"
                >
                  <ArrowLeft
                    size="14px"
                    className="me-2"
                  />

                  Back to list
                </Link>

                <Dropdown
                  align="end"
                >
                  <Dropdown.Toggle
                    variant="info"
                    role="button"
                    className="d-flex align-items-center"
                  >
                    <Tool
                      size="14px"
                      className="me-3"
                    />

                    Manage {name.singular}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    { options(resource, helpers) }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            { views !== undefined && (
              <HeaderTabs
                tabs={views}
                value={view}
                onChange={value => setView(value)}
              />
            ) }
          </div>
        </div>
        
        { children(resource, view, helpers) }
      </Container>
    </>
  )
}

import { useField, useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

import api from '../../utils/api'
import ErrorFeedback from '../errors/ErrorFeedback'
import SelectAsyncVendor, { SelectAsyncProps } from '../vendor/SelectAsync'

export interface BelongsToProps {
  name: string,
  labelName: string,
  label: string,
  endpoint: string
}

export default function BelongsTo({ name, labelName, label, endpoint, ...props }: BelongsToProps & SelectAsyncProps) {
  const abortController = useRef<AbortController>(new AbortController())

  const [field, meta, helpers] = useField(name)
  const context = useFormikContext<{ [key: string]: any }>()

  useEffect(() => {
    return () => abortController.current.abort()
  }, [])

  return (
    <>
      <SelectAsyncVendor
        className={meta.error && 'is-invalid'}
        id={name}
        name={name}
        placeholder={label}
        defaultOptions
        loadOptions={(inputValue: string) => {
          abortController.current.abort()
          abortController.current = new AbortController()

          return new Promise<{[key: string]: any}[]>((resolve, reject) => {
            const url = inputValue !== '' 
              ? `${endpoint}?search=${inputValue}` 
              : endpoint

            api.get(url, { signal: abortController.current.signal })
              .then(response => {
                resolve(response?.data?.data)
              })
              .catch(err => {
                reject(err)
              })
          })
        }}
        value={field.value != '' ? { id: field.value, name: context.values[labelName] } : undefined}
        onChange={(option: any) => {
          context.setFieldValue(name, option.id)
          context.setFieldValue(labelName, option.name)
        }}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.id}
        {...props}
      />

      <ErrorFeedback error={meta.error} />
    </>
  )
}

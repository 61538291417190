import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Briefcase, DollarSign, Users } from 'react-feather'

import CardValue from '../components/CardValue'
import { currencyFormat } from '../helpers'
import api from '../utils/api'

import Loading from './aux/Loading'

interface DashboardData {
  user_count: number;
  investor_count: number;
  invested_amount: number;
}

export default function Dashboard() {
  const abortController = new AbortController()

  const [data, setData] = useState<DashboardData|null>(null)

  useEffect(() => {
    api.get('meta/dashboard', { signal: abortController.signal })
      .then(response => {
        if (response !== undefined) {
          setData(response.data.data)
        }
      })

    return () => abortController.abort()
  }, [])

  if (data === null) {
    return <Loading />
  }

  return (
    <>
      <div className="header mt-2">
        <Container fluid>
          <div className="header-body">
            <h1 className="header-title">
              Dashboard
            </h1>
          </div>
        </Container>
      </div>

      <Container fluid>
        <Row>
          <Col
            xs={12}
            md={4}
          >
            <CardValue
              title="Users"
              value={data.user_count}
              icon={<Users />}
            />
          </Col>
          
          <Col
            xs={12}
            md={4}
          >
            <CardValue
              title="Investors"
              value={data.investor_count}
              icon={<Briefcase />}
            />
          </Col>
          
          <Col
            xs={12}
            md={4}
          >
            <CardValue
              title="Invested amount"
              value={currencyFormat(data.invested_amount)}
              icon={<DollarSign />}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

import { ArrowUpRight } from 'react-feather'
import { Link } from 'react-router-dom'

import ResourceDetailExternalAction from '../components/ResourceDetailExternalAction'
import ResourceDetailGroup from '../components/ResourceDetailGroup'
import ResourceDetailHeading from '../components/ResourceDetailHeading'
import ResourceDetailRow from '../components/ResourceDetailRow'
import ResourceTable from '../components/ResourceTable'
import InvestorUserAttach from '../components/actions/InvestorUserAttach'
import InvestorUserReview from '../components/actions/InvestorUserReview'
import ResourceDelete from '../components/actions/ResourceDelete'
import KycStatus from '../components/columns/KycStatus'
import { countryName, currencyFormat } from '../helpers'

import ResourceShow from './generic/ResourceShow'

enum Views {
  Info = 'info',
  Investments = 'investments',
  Users = 'users'
}

export default function InvestorShow() {
  return (
    <ResourceShow
      name={{ singular: 'investor', plural: 'investors' }}
      views={[
        {
          label: 'Information',
          value: Views.Info,
        },
        {
          label: 'Investments',
          value: Views.Investments,
        },
        {
          label: 'Users',
          value: Views.Users,
        },
      ]}
      options={(investor, helpers) => (
        <>
          <InvestorUserAttach
            parent="investor"
            parentId={investor.id}
            onSuccess={() => { helpers.loadResource(); helpers.setView(Views.Users) }}
          />

          <ResourceDelete
            resourceName="investor"
            endpoint={`/investors/${investor.id}`}
            redirectTo="/investors"
            disabled={true}
          />
        </>
      )}
    >
      { (investor, view, helpers) => (
        <>
          { view === Views.Info && (
            <>
              <ResourceDetailGroup 
                title="Identification details"
              >
                <ResourceDetailRow label="Type">
                  { investor.type === 'natural' && 'Natural person' }

                  { investor.type === 'legal' && 'Legal entity' }
                </ResourceDetailRow>

                { investor.type === 'natural' && (
                  <ResourceDetailRow label="Related user">
                    <Link to={`/users/${investor.user_id}`}>
                      { investor.name }

                      <ArrowUpRight
                        size="16px"
                        className="ms-2"
                      />
                    </Link>
                  </ResourceDetailRow>
                ) }

                { investor.type === 'legal' && (
                  <>
                    <ResourceDetailRow label="Entity name">
                      { investor.name }
                    </ResourceDetailRow>

                    <ResourceDetailRow label="Company number">
                      { investor.entity_number }
                    </ResourceDetailRow>

                    <ResourceDetailRow label="Address">
                      { investor.entity_address }
                    </ResourceDetailRow>

                    <ResourceDetailRow label="Country">
                      { countryName(investor.entity_country) }
                    </ResourceDetailRow>
                  </>
                ) }
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Verification"
              >
                <ResourceDetailRow label="KYC status">
                  <KycStatus value={investor.kyc_status} />
                </ResourceDetailRow>

                <ResourceDetailRow label="Sumsub applicant ID">
                  { investor.sumsub_applicant_id && (
                    <ResourceDetailExternalAction
                      title="View on Sumsub"
                      href={`https://cockpit.sumsub.com/checkus#/applicant/${ investor.sumsub_applicant_id }/basicInfo?clientId=bageera.co_35223`}
                    />
                  ) }

                  { investor.sumsub_applicant_id ?? '—' }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              { investor.type === 'natural' && (
                <ResourceDetailGroup 
                  title="Employment details"
                >
                  <ResourceDetailRow label="Employment status">
                    { investor.employment_status === 'employed' && 'Employed' }

                    { investor.employment_status === 'selfemployed' && 'Self-employed' }

                    { investor.employment_status === 'retired' && 'Retired' }

                    { investor.employment_status === 'student' && 'Student' }
                  </ResourceDetailRow>

                  { !['retired', 'student'].includes(investor.employment_status) && (
                    <ResourceDetailRow label="Profession">
                      { investor.profession }
                    </ResourceDetailRow>
                  ) }
                </ResourceDetailGroup>
              ) }

              <ResourceDetailGroup 
                title="Bank account"
              >
                <ResourceDetailRow label="Account IBAN">
                  { investor.iban || '–' }
                </ResourceDetailRow>

                <ResourceDetailRow label="Account SWIFT / BIC">
                  { investor.swift_bic || '–' }
                </ResourceDetailRow>
              </ResourceDetailGroup>

              <ResourceDetailGroup 
                title="Others"
              >
                <ResourceDetailRow label="Creation date">
                  { investor.created_at }
                </ResourceDetailRow>

                <ResourceDetailRow label="Last update date">
                  { investor.updated_at }
                </ResourceDetailRow>
              </ResourceDetailGroup>
            </>
          ) }

          { view === Views.Investments && (
            <>
              <ResourceDetailHeading>
                Related investments
              </ResourceDetailHeading>

              <ResourceTable
                endpoint={`/investors/${investor.id}/investments`}
                columns={[
                  {
                    key: 'offering',
                    header: 'Offering',
                    headerClass: 'col-3',
                    rowClass: 'overflow-ellipsis',
                    render: (row: { [key: string]: any }) => (
                      <>
                        { row.offering_name }
              
                        <Link
                          to={`/offerings/${row.offering_id}`}
                        >
                          <ArrowUpRight
                            size="16px"
                            className="ms-2"
                          />
                        </Link>
                      </>
                    )
                  },
                  {
                    key: 'amount',
                    header: 'Amount',
                    render: (row: { [key: string]: any }) => currencyFormat(row.amount, row.currency)
                  },
                  {
                    key: 'status',
                    header: 'Status',
                    accessor: 'status'
                  },
                  {
                    key: 'updated_at',
                    header: 'Updated',
                    accessor: 'updated_at'
                  }
                ]}
                
                actions={[
                  {
                    key: 'view',
                    render: (row: { [key: string]: any }) => (
                      <Link
                        to={`/investments/${row.id}`}
                        className="btn btn-sm btn-primary"
                      >
                        View
                      </Link>
                    )
                  }
                ]}
              />
            </>
          ) }

          { view === Views.Users && (
            <>
              <ResourceDetailHeading>
                Related users
              </ResourceDetailHeading>

              <ResourceTable
                endpoint={`/investors/${investor.id}/users`}
                columns={[
                  {
                    key: 'name',
                    header: 'Name',
                    accessor: 'name'
                  },
                  {
                    key: 'email',
                    header: 'Email',
                    accessor: 'email'
                  },
                  {
                    key: 'country',
                    header: 'Country',
                    render: (row: { [key: string]: any }) => countryName(row.country)
                  },
                  {
                    key: 'kyc_status',
                    header: 'KYC',
                    headerClass: 'min-width',
                    rowClass: 'text-center',
                    render: (row: { [key: string]: any }) => <KycStatus value={row.kyc_status} />
                  },
                  {
                    key: 'authority',
                    header: 'Authority',
                    headerClass: 'min-width',
                    rowClass: 'text-center',
                    render: (row: { [key: string]: any }) => <KycStatus value={row.authority_status} />
                  }
                ]}
                
                actions={[
                  {
                    key: 'view',
                    render: (row: { [key: string]: any }) => (
                      <InvestorUserReview
                        parent="investor"
                        pivotId={row.pivot_id}
                        onSuccess={helpers.loadResource}
                      />
                    )
                  },
                ]}
              />
            </>
          ) }
        </>
      ) }
    </ResourceShow>
  )
}

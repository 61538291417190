import classNames from 'classnames'
import { useField } from 'formik'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

import ErrorFeedback from '../errors/ErrorFeedback'

export interface CurrencyProps {
  name: string,
  label: string
}

export default function Currency({ name, label, ...props }: CurrencyProps & NumberFormatProps) {
  const [field, meta, helpers] = useField({ type: 'number', name: name })

  return (
    <>
      <NumberFormat
        className={classNames('form-control', meta.error && 'is-invalid')}
        id={name}
        name={name}
        placeholder={label}
        inputMode="numeric"
        isNumericString={true}
        thousandSeparator={true}
        prefix="€"
        value={field.value}
        onValueChange={val => helpers.setValue(val.floatValue)}
        {...props}
      />

      <ErrorFeedback error={meta.error} />
    </>
  )
}

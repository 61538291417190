import { AlertTriangle, Check, X } from 'react-feather'

export default function KycStatus({ value }: { value: 'verified'|'pending'|'not_verified' }) {
  if (value === 'verified') {
    return (
      <span
        className="text-success cursor-help"
        title="Verified"
      >
        <Check size="20px" />
      </span>
    )
  }

  if (value === 'pending') {
    return (
      <span
        className="text-danger cursor-help"
        title="Pending"
      >
        <AlertTriangle size="20px" />
      </span>
    )
  }

  if (value === 'not_verified') {
    return (
      <span
        className="text-danger cursor-help"
        title="Not verified"
      >
        <X size="20px" />
      </span>
    )
  }

  return null
}

import classNames from 'classnames'
import flatpickr from 'flatpickr'
import { useField } from 'formik'
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr'

import ErrorFeedback from '../errors/ErrorFeedback'

import 'flatpickr/dist/flatpickr.css'

export interface DateTimeProps {
  name: string;
  label: string;
  className?: string;
  options?: flatpickr.Options.Options;
}

export default function DateTime({ name, label, className, options = {}, ...props }: DateTimeProps & DateTimePickerProps) {
  const [field, meta, helpers] = useField(name)

  return (
    <>
      <Flatpickr
        className={classNames(
          'form-control',
          meta.error && 'is-invalid',
          className
        )}
        id={name}
        name={name}
        placeholder={label}
        value={field.value}
        onChange={(selectedDates, dateStr, instance) => helpers.setValue(dateStr)}
        options={{
          enableTime: true,
          dateFormat: 'Y-m-d H:i',
          time_24hr: true,
          ...options
        }}
        {...props}
      />

      <ErrorFeedback error={meta.error} />
    </>
  )
}

import axios from 'axios'
import className from 'classnames'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, ListGroup, Modal } from 'react-bootstrap'
import { ArrowUpRight, Download, RefreshCw } from 'react-feather'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'
import ResourceDetailGroup from '../ResourceDetailGroup'
import ResourceDetailRow from '../ResourceDetailRow'
import ResourceDetailRowEdit from '../ResourceDetailRowEdit'
import Dropzone from '../fields/Dropzone'
import Select from '../fields/Select'


enum Status {
  ShowButton,
  LoadingModal,
  ShowModal,
  ReplaceProofOfAuthority,
  ConfirmDelete,
  Deleting
}

export default function InvestorUserReview({ parent, pivotId, onSuccess }: { parent: 'investor'|'user', pivotId: number, onSuccess: () => void }) {
  const abortController = new AbortController()

  const [status, setStatus] = useState<Status>(Status.ShowButton)
  const [pivotData, setPivotData] = useState<{[key: string]: any}|null>(null)

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  return (
    <>
      <LoadingButton
        busy={status === Status.LoadingModal}
        onClick={() => {
          setStatus(Status.LoadingModal)

          api.get(`/investor_user/${pivotId}`, { signal: abortController.signal })
            .then(response => {
              if (response !== undefined) {
                setPivotData(response.data.data)
                setStatus(Status.ShowModal)
              }
            })
        }}
        className="d-flex align-items-center"
        size="sm"
        variant="primary"
      >
        View
      </LoadingButton>

      { pivotData !== null && (
        <>
          <Modal
            show={status === Status.ShowModal || status === Status.ReplaceProofOfAuthority}
            onHide={() => setStatus(Status.ShowButton)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="mb-0">
                Related {parent === 'user' ? 'investor' : 'user'} details
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Formik
                initialValues={{
                  authority_status: pivotData.authority_status,
                  proof_of_authority: null
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await api.put(`/investor_user/${pivotId}`, values, { signal: abortController.signal })

                    if (response !== undefined) {
                      toast.success('Related user updated successfully.')
                      setStatus(Status.ShowButton)
                      onSuccess()
                    }
                  }
                  catch (err) {
                    if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
                      actions.setErrors(err.response.data.errors)
                    }
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <ResourceDetailGroup>
                      { parent === 'investor' && (
                        <ResourceDetailRow label="User">
                          <Link to={`/users/${pivotData.user_id}`}>
                            { pivotData.user_name }

                            <ArrowUpRight
                              size="16px"
                              className="ms-2"
                            />
                          </Link>
                        </ResourceDetailRow>
                      ) }

                      { parent === 'user' && (
                        <ResourceDetailRow label="Investor">
                          <Link to={`/investors/${pivotData.investor_id}`}>
                            { pivotData.investor_name }

                            <ArrowUpRight
                              size="16px"
                              className="ms-2"
                            />
                          </Link>
                        </ResourceDetailRow>
                      ) }

                      <ResourceDetailRow label="Relationship">
                        { pivotData.type === 'natural_person' && 'Natural person' }

                        { pivotData.type === 'legal_representative' && 'Legal representative' }
                      </ResourceDetailRow>

                      <ResourceDetailRowEdit label="Authority status">
                        <Select
                          name="authority_status"
                          label="Authority status"
                          isSearchable={false}
                          options={[
                            { label: 'Verified', value: 'verified' },
                            { label: 'Pending', value: 'pending' },
                            { label: 'Not verified', value: 'not_verified' }
                          ]}
                        />
                      </ResourceDetailRowEdit>

                      { (pivotData.type !== 'natural_person' && (pivotData.proof_of_authority === null || status === Status.ReplaceProofOfAuthority)) && (
                        <ResourceDetailRowEdit label="Proof of authority">
                          <Dropzone
                            accept="application/pdf,image/jpeg"
                            name="proof_of_authority"
                            placeholder="Proof of authority"
                            footer="Accepted formats: .pdf · Maximum size: 10 MB"
                            divProps={{className: 'my-2'}}
                          />
                        </ResourceDetailRowEdit>
                      ) }

                      { (pivotData.proof_of_authority !== null && status !== Status.ReplaceProofOfAuthority) && (
                        <ListGroup.Item
                          className="px-4"
                        >
                          <div className="d-flex justify-content-between align-items-center text-muted mb-3 pb-1">
                            Proof of authority

                            <div className="d-flex">
                              <a
                                href={pivotData.proof_of_authority}
                                target="_blank"
                                className="btn btn-sm btn-outline-secondary d-flex align-items-center"
                                rel="noreferrer"
                              >
                                <Download
                                  size="14px"
                                  className="me-2"
                                /> Download
                              </a>

                              <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary d-flex align-items-center ms-2"
                                onClick={() => setStatus(Status.ReplaceProofOfAuthority)}
                              >
                                <RefreshCw
                                  size="14px"
                                  className="me-2"
                                />
                                Replace
                              </button>
                            </div>
                          </div>

                          <iframe
                            src={`${pivotData.proof_of_authority}#toolbar=0`}
                            width="100%"
                            height="300"
                            title="Offering summary"
                          />
                        </ListGroup.Item>
                      ) }
                    </ResourceDetailGroup>

                    <div
                      className={className(
                        'd-flex',
                        pivotData.type === 'natural_person' ? 'justify-content-end' : 'justify-content-between',
                      )}
                    >
                      { pivotData.type !== 'natural_person' && (
                        <Button
                          variant="outline-secondary"
                          className="px-5"
                          onClick={() => setStatus(Status.ConfirmDelete)}
                        >
                          Detach {parent === 'user' ? 'investor' : 'user'}
                        </Button>
                      ) }

                      <LoadingButton
                        busy={isSubmitting}
                        type="submit"
                        className="btn btn-info px-5"
                      >
                        Update status
                      </LoadingButton>
                    </div>
                  </Form>
                ) }
              </Formik>
            </Modal.Body>
          </Modal>

          <Modal
            show={status === Status.ConfirmDelete || status === Status.Deleting}
            onHide={() => setStatus(Status.ShowModal)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title className="mb-0">
                Detach {parent === 'user' ? 'investor' : 'user'}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p className="mb-4">
                Are you sure you want to detach the related {parent === 'user' ? 'investor' : 'user'} <b>{parent === 'user' ? pivotData.investor_name : pivotData.user_name}</b>? This action cannot be undone.
              </p>

              <p className="text-right mb-0">
                <Button
                  variant="link"
                  className="text-secondary me-2"
                  onClick={() => setStatus(Status.ShowModal)}
                >
                  Cancel
                </Button>

                <LoadingButton
                  busy={status === Status.Deleting}
                  variant="danger"
                  onClick={() => {
                    setStatus(Status.Deleting)

                    api.delete(`investor_user/${pivotId}`, { signal: abortController.signal })
                      .then(response => {
                        if (response !== undefined) {
                          toast.success((parent === 'user' ? 'Investor' : 'User') + ' detached successfully.')
                          setStatus(Status.ShowButton)
                          onSuccess()
                        }
                      })
                      .catch(err => {
                        setStatus(Status.ShowModal)
                      })
                  }}
                >
                  Detach {parent === 'user' ? 'investor' : 'user'}
                </LoadingButton>
              </p>
            </Modal.Body>
          </Modal>
        </>
      ) }
    </>
  )
}
